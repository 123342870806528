

















































































































































































import { Component, Vue, PropSync } from "vue-property-decorator";
import store from "@/store";
//  types
import { CiiData } from "@/types/ciiData";
import { Vessel } from "@/types/Vessel";
import { TableHeader, TableRow, headers } from "./VTableData";
//  modules
import { getModule } from "vuex-module-decorators";
import DashboardModule from "@/store/clients/Dashboard.module";
import UserModule from "@/store/clients/User.module";
import VesselsModule from "@/store/clients/Vessels.module";

const Dash = getModule(DashboardModule, store);
const User = getModule(UserModule, store);
const Vessels = getModule(VesselsModule, store);

@Component({
  components: {},
})
export default class TableView extends Vue {
  @PropSync("data", { type: Array, default: [] }) ciiData!: CiiData[];
  @PropSync("loading", { type: Boolean, default: false }) isDataLoading!: boolean;
  @PropSync("noDataVessels", { type: Array, default: [] }) vesselsNoDataList!: Vessel[];

  headers = headers;
  //  CII calculations use deadweight or gross tonnage depending on vessel type.
  //  useDeadweight is a list of vessels that require a deadweight for calculations. Other types require gross tonnage.
  useDeadweight = ["Containership", "Tanker", "LNG carrier", "Gas carrier", "Bulk carrier", "General cargo ship", "Refrigerated cargo carrier", "Combination carrier"];

  get tableHeaders(): TableHeader[] | undefined {
    if (!this.ciiData.length && !this.vesselsNoDataList.length) return;
    return this.headers;
  }

  get tableRowItems(): TableRow[] {
    if (!this.ciiData.length) return [];

    const tableRows: TableRow[] = [];
    this.ciiData.forEach((item: CiiData) => {
      const row: TableRow = {
        imoNumber: item.vessel.imoNumber,
        vesselName: item.vessel.name,
        deadweight: this.vesselWeight(item.vessel.id),
        distanceTravelledInNm: Number(item.distanceTravelledInNm.toFixed(1)),
        co2EmissionsInGrams: Number((item.co2EmissionsInGrams / 1000000).toFixed(1)),
        attainedCii: Number(item.attainedCii.toFixed(2)),
        referenceLine: Number(item.referenceLine.toFixed(2)),
        requiredCii: Number(item.requiredCii.toFixed(2)),
        ciiRating: item.ciiRating,
        ciiTrend: item.ciiTrend,
      };
      tableRows.push(row);
    });
    return tableRows;
  }

  get isNoData(): boolean {
    return !(this.tableRowItems.length || this.vesselsNoDataList.length);
  }

  get isPartOfSharedDashboard(): boolean {
    return Dash.activeDashboard.userId !== User.userId;
  }

  customSort(items: [], sortBy: any, isDesc: any): [] {
    //do nothing when the sorting is reset
    if (!sortBy[0]) return items;

    //sort as strings
    if (sortBy[0] === "vesselName" || sortBy[0] === "ciiRating") {
      return items.sort((a: any, b: any) => {
        if (isDesc[0]) return b[sortBy] < a[sortBy] ? -1 : 1;

        return a[sortBy] < b[sortBy] ? -1 : 1;
      });
    }

    //sort all other columns as numbers
    return items.sort((a: any, b: any) => {
      return isDesc[0] ? ((Number(b[sortBy]) || 0) < (Number(a[sortBy]) || 0) ? -1 : 1) : (Number(a[sortBy]) || 0) < (Number(b[sortBy]) || 0) ? -1 : 1;
    });
  }

  ratingColor(rating: string): string {
    switch (rating) {
      case "A":
        return "#33a357";
      case "B":
        return "#c3d545";
      case "C":
        return "#fff12c";
      case "D":
        return "#edb731";
      case "E":
        return "#cc232a";
      default:
        return "";
    }
  }

  ciiTrendStyles(trend: { penultimate7DaysAverage: number; last7DaysAverage: number }): { icon: string; color: string } {
    const isDowntrend = trend.last7DaysAverage > trend.penultimate7DaysAverage;
    return {
      icon: isDowntrend ? "mdi-arrow-up-thin" : "mdi-arrow-down-thin",
      color: isDowntrend ? "#cc232a" : "#33a357",
    };
  }

  vesselWeight(vesselId: number): number | string | null {
    const vessel = Vessels.getExtendedVesselById(vesselId);
    if (!vessel) return "No Data";
    if (this.isDeadweightRequired(vessel.vesselType)) return vessel.deadweight ?? null;
    else return vessel.grossTonnage;
  }

  isDeadweightRequired(vesselType: { id: number; name: string } | null): boolean {
    if (!vesselType) return true;
    return this.useDeadweight.includes(vesselType?.name);
  }
}
