export const tableHeaders = [
  {
    text: "Vessel name",
    align: "start",
    sortable: true,
    value: "name",
  },
  { text: "WARNING", align: "center", sortable: false, value: "warningIncidents" },
  { text: "CRITICAL", align: "center", sortable: false, value: "criticalIncidents" },
];
