export const tableHeaders = [
  {
    text: "Vessel name",
    align: "start",
    sortable: true,
    value: "name",
  },
  { text: "Speed", align: "center", sortable: false, value: "speed" },
  { text: "RPM", align: "center", sortable: false, value: "rpm" },
  { text: "SFR", align: "center", sortable: false, value: "sfr" },
];
