





















































































































































import { Component, Ref, Vue, Prop } from "vue-property-decorator";
import store from "@/store";
import itemMixin from "@/mixins/itemMixin.js";
// modules
import { getModule } from "vuex-module-decorators";
import VesselsModule from "@/store/clients/Vessels.module";
import DashboardModule from "@/store/clients/Dashboard.module";
import FoulingOverviewWidgetModule from "@/store/clients/FoulingOverviewWidget.module";
import UserModule from "@/store/clients/User.module";
import SnackbarModule from "@/store/clients/Snackbar.module";
// types
import { Widget } from "@/types/widget";
import { ExtendedVessel } from "@/types/Vessel";
import { FoulingOverviewWidgetConfig } from "@/types/widgetFoulingOverview";
import { tableHeaders, TableRow } from "./VTableData";
// components
import WidgetEditDialog from "@/components/widgets/WidgetEditDialog.vue";
import VesselSelect from "@/components/VesselSelect.vue";
import LoadingTextWave from "@/components/LoadingTextWave.vue";
import TableRecord from "./TableRecord.vue";

const Vessels = getModule(VesselsModule, store);
const Dash = getModule(DashboardModule, store);
const User = getModule(UserModule, store);
const Snackbar = getModule(SnackbarModule, store);
const FoulingOverviewWidgetConfig = getModule(FoulingOverviewWidgetModule, store);

@Component({
  mixins: [itemMixin],
  components: {
    WidgetEditDialog,
    VesselSelect,
    LoadingTextWave,
    TableRecord,
  },
})
export default class FoulingOverviewWidget extends Vue {
  @Prop() widgetRef!: Widget;
  @Ref("WidgetEditDialog") WidgetEditDialog!: WidgetEditDialog;

  config!: FoulingOverviewWidgetConfig;
  tableHeaders = tableHeaders;
  selectedVessels: ExtendedVessel[] = [];
  initialSelectedVessels: ExtendedVessel[] = [];
  isModalActive = false;
  isDataLoading = false;

  get isPartOfSharedDashboard(): boolean {
    return Dash.activeDashboard.userId !== User.userId;
  }

  get extendedVessels(): ExtendedVessel[] {
    return Vessels.extendedVessels;
  }

  get tableRows(): TableRow[] {
    const tableRows: TableRow[] = [];
    this.initialSelectedVessels.forEach(vessel => {
      const tableRow: TableRow = {
        id: vessel.id,
        name: vessel.name,
      };

      tableRows.push(tableRow);
    });

    return tableRows;
  }

  async getConfig(): Promise<void> {
    this.config = await FoulingOverviewWidgetConfig.getConfigById(this.widgetRef.configId);
  }

  async getFoulingDetails(vesselIds: number[]): Promise<void> {
    const promises: Promise<any>[] = [
      FoulingOverviewWidgetConfig.fetchFoulingConfigs(vesselIds),
      FoulingOverviewWidgetConfig.fetchHullCoatingForVessels(vesselIds),
      FoulingOverviewWidgetConfig.fetchLatestSpeedLossStatisticsForVessels(vesselIds),
      FoulingOverviewWidgetConfig.fetchAddedFuelConsumptionForVessels(vesselIds),
    ];

    await Promise.all(promises);
  }

  async saveConfig(): Promise<void> {
    this.isDataLoading = true;
    try {
      await FoulingOverviewWidgetConfig.updateConfig({ ...this.config, vessels: this.selectedVessels });
      await this.getConfig();
      await this.getFoulingDetails(this.config.vessels.map(vessel => vessel.id));
      this.initialSelectedVessels = this.config.vessels;
      this.selectedVessels = this.initialSelectedVessels;
      Snackbar.showSnackbar({ text: "Fouling overview widget config successfully updated", color: "success" });
    } catch (error) {
      Snackbar.showSnackbar({ text: "Failed to update Fouling overview widget config" });
    } finally {
      this.isDataLoading = false;
      this.isModalActive = false;
    }
  }

  onModalClose(): void {
    this.closeEditDialog();
  }

  async onSaveWidgetName(name: string): Promise<void> {
    await Dash.updateWidget({ id: this.widgetRef.id, name });
  }

  closeEditDialog(): void {
    this.isModalActive = false;
    setTimeout(() => {
      this.selectedVessels = this.initialSelectedVessels;
    }, 100);
  }

  async createNewConfig(): Promise<void> {
    try {
      const newConfig = await FoulingOverviewWidgetConfig.addNewConfig();
      await Dash.updateWidget({ id: this.widgetRef.id, configId: newConfig.id });
      Snackbar.showSnackbar({ text: "Config for this widget could not be found, so a new one has been created", color: "primary" });
    } catch (error) {
      console.error(error);
    }
  }

  async initWidgetData(): Promise<void> {
    this.isDataLoading = true;
    try {
      await this.getConfig();
      await this.getFoulingDetails(this.config.vessels.map(vessel => vessel.id));
      this.initialSelectedVessels = this.config.vessels;
      this.selectedVessels = this.initialSelectedVessels;
    } catch (error) {
      console.error(error);
    } finally {
      this.isDataLoading = false;
    }
  }

  async created(): Promise<void> {
    if (Vessels.extendedVesselsExpired) await Vessels.refreshExtendedVessels();
    await this.initWidgetData();
  }
}
