





































































































































































































































































import { Component, Vue, Ref, Prop, Watch } from "vue-property-decorator";
import store from "@/store";
//  utilities
import itemMixin from "@/mixins/itemMixin.js";
import moment from "moment";
import dateHelper from "Utilities/date-helper";
//  types
import { VesselDataWidgetConfig } from "@/types/VesselDataWidgetConfig";
import { Widget } from "@/types/widget";
import { VesselDataWidgetColumn } from "@/types/VesselDataWidgetColumn";
import { ExtendedVessel } from "@/types/Vessel";
import { LogVariable } from "@/types/logVariable";
//  components
import WidgetEditDialog from "@/components/widgets/WidgetEditDialog.vue";
import TimeSpanDialog from "@/components/widgets/TimeSpanDialog.vue";
import LogVariablesDialog from "@/components/widgets/LogVariablesDialog.vue";
import LogVariablesColumn from "@/components/widgets/LogVariablesColumn.vue";
import VesselSelect from "@/components/VesselSelect.vue";
import VesselDataTable from "./VesselDataTable.vue";
import VesselDataGraph from "./VesselDataGraph.vue";
//  modules
import { getModule } from "vuex-module-decorators";
import VesselDataWidgetConfigurationModule from "@/store/clients/VesselDataWidgetConfig.module";
import LogDataModule from "@/store/clients/LogData.module";
import DashboardModule from "@/store/clients/Dashboard.module";
import UserModule from "@/store/clients/User.module";
import VesselsModule from "@/store/clients/Vessels.module";
import LogVariablesModule from "@/store/clients/LogVariables.module";

const VesselDataWidgetConfiguration = getModule(VesselDataWidgetConfigurationModule, store);
const LogData = getModule(LogDataModule, store);
const Dash = getModule(DashboardModule, store);
const User = getModule(UserModule, store);
const Vessels = getModule(VesselsModule, store);
const LogVariables = getModule(LogVariablesModule, store);

@Component({
  mixins: [itemMixin],
  components: {
    WidgetEditDialog,
    TimeSpanDialog,
    LogVariablesDialog,
    LogVariablesColumn,
    VesselSelect,
    VesselDataTable,
    VesselDataGraph,
  },
})
export default class VesselDataWidget extends Vue {
  @Prop() widgetRef!: Widget;
  @Ref("WidgetEditDialog") WidgetEditDialog!: WidgetEditDialog;

  selectedVessels: ExtendedVessel[] = [];
  initialSelectedVessels: ExtendedVessel[] = [];
  panels = [0, 1]; // indexes of expansion panels to be default open
  isModalActive = false;
  isDataLoading = false;
  isLogVariablesModalActive = false;
  isTimeSpanModalActive = false;
  timeSpanError = false;
  resized = false;
  selectedVessel: any = null;
  selectedColumnIndex = 0;
  config: VesselDataWidgetConfig = {
    id: 0,
    configurationName: "Table widget",
    vessels: [],
    columns: [],
  };
  initialConfig: VesselDataWidgetConfig = {
    id: 0,
    configurationName: "Table widget",
    vessels: [],
    columns: [],
  };

  //let Highcharts know it has been resized, otherwise it won't trigger chart reflow
  //resizing is a computed property in the itemMixin
  @Watch("resizing")
  onResize(val: boolean): void {
    if (val) this.resized = val;
    setTimeout(() => (this.resized = false));
  }

  get VesselDataWidgetType(): string {
    return this.widgetRef.type?.displayType ?? "VesselDataTable";
  }

  get isVesselDataWidgetTypeTable(): boolean {
    return Boolean(this.VesselDataWidgetType === "VesselDataTable");
  }

  get vesselDataWidgetTypeClass(): Object {
    return {
      "vessel-data-table": this.VesselDataWidgetType === "VesselDataTable",
      "vessel-data-graph": this.VesselDataWidgetType === "VesselDataGraph",
    };
  }

  get extendedVessels(): ExtendedVessel[] {
    return Vessels.extendedVessels;
  }

  get isPartOfSharedDashboard(): boolean {
    return Dash.activeDashboard.userId !== User.userId;
  }

  get isTableEditable(): boolean {
    return Boolean(this.initialConfig.columns.length);
  }

  get defaultInfo(): boolean {
    return !this.config.vessels.length && !this.config.columns.length;
  }

  get isAddColumnDisabled(): boolean {
    return Boolean(this.config.vessels.length === 0);
  }

  get hasColumnTimeSpan(): boolean {
    return this.config.columns.every((column: VesselDataWidgetColumn) => column.calculationType);
  }

  onVesselChange(toggledVessel: ExtendedVessel): void {
    if (!this.isVesselLogVariablesLoaded(toggledVessel.id)) LogVariables.fetchAllLogVariablesByVesselId(toggledVessel.id);
    this.config.columns.forEach(column => {
      column.logVariables = column.logVariables.filter(variable => variable.vesselId !== toggledVessel.id);
    });
    if (this.config.vessels.length === 1 && this.config.columns.length === 0) {
      this.addColumn();
    }
  }

  isVesselLogVariablesLoaded(vesselId: number): boolean {
    return Boolean(LogVariables.vesselsLogVariables.find(vessel => vessel.vesselId === vesselId)?.logVariables);
  }

  async fetchCalculatedLogData(config: VesselDataWidgetConfig): Promise<VesselDataWidgetConfig> {
    for (const [index, column] of config.columns.entries()) {
      const logVariableIds = column.logVariables.map(variable => variable.id);
      if (column.calculationType !== "FromAndToDates") {
        column.fromDate = moment.utc(new Date(), "YYYY-MM-DD").subtract(dateHelper.getDatesDiffAsHours(column.fromDate, column.toDate), "hours").format();
        column.toDate = moment.utc(new Date(), "YYYY-MM-DD").format();
      }
      let result;

      switch (column.calculationType) {
        case "LastReceived":
          result = await LogData.fetchLastReceivedLogData({
            logVariableIds: logVariableIds.filter((id: number) => Number.isInteger(id)).join(),
          });
          break;
        case "LastVoyageAveraged":
          result = await LogData.fetchLastVoyageAveraged({
            logVariableIds: logVariableIds.filter((id: number) => Number.isInteger(id)).join(),
          });
          break;
        case "LastVoyageAccumulated":
          result = await LogData.fetchLastVoyageAccumulated({
            logVariableIds: logVariableIds.filter((id: number) => Number.isInteger(id)).join(),
          });
          break;
        case "Averaged":
          result = await LogData.fetchAveragedCalculatedLogData({
            logVariableIds: logVariableIds.filter((id: number) => Number.isInteger(id)).join(),
            fromDate: column.fromDate,
            toDate: column.toDate,
          });
          break;
        case "Accumulated":
          result = await LogData.fetchAccumulatedCalculatedLogData({
            logVariableIds: logVariableIds.filter((id: number) => Number.isInteger(id)).join(),
            fromDate: column.fromDate,
            toDate: column.toDate,
          });
          break;
        case "FromAndToDates":
          if (column.fromAndToDatesCalculationType === "Averaged") {
            result = await LogData.fetchAveragedCalculatedLogData({
              logVariableIds: logVariableIds.filter((id: number) => Number.isInteger(id)).join(),
              fromDate: column.fromDate,
              toDate: column.toDate,
            });
          } else if (column.fromAndToDatesCalculationType === "Accumulated") {
            result = await LogData.fetchAccumulatedCalculatedLogData({
              logVariableIds: logVariableIds.filter((id: number) => Number.isInteger(id)).join(),
              fromDate: column.fromDate,
              toDate: column.toDate,
            });
          }
          break;
        default:
          break;
      }

      if (Boolean(result)) Vue.set(config.columns[index], "cachedResults", result);
    }
    return config;
  }

  async saveConfig(): Promise<void> {
    if (!this.hasColumnTimeSpan) {
      this.timeSpanError = true;
      return;
    }
    this.isDataLoading = true;
    this.config = await this.fetchCalculatedLogData(this.config);
    await VesselDataWidgetConfiguration.updateVesselDataWidgetConfig(this.config);
    this.initialConfig = JSON.parse(JSON.stringify(this.config));
    this.isDataLoading = false;
    this.closeEditDialog();
  }

  openTimeSpanDialog(columnIndex: number): void {
    this.selectedColumnIndex = columnIndex;
    this.isTimeSpanModalActive = true;
  }

  async initDisplayNameOfConfig(): Promise<void> {
    for (const vessel of this.config.vessels.values()) {
      if (!this.isVesselLogVariablesLoaded(vessel.id)) {
        await LogVariables.fetchAllLogVariablesByVesselId(vessel.id);
      }
    }

    for (var i = 0; i < this.config.columns.length; i++) {
      const columnLogVariables = this.config.columns[i].logVariables;
      for (var y = 0; y < columnLogVariables.length; y++) {
        const logVariable = columnLogVariables[y];
        if (logVariable.vesselId) {
          const vesselLogVariables = this.getVesselLogVariables(logVariable.vesselId);

          if (vesselLogVariables) {
            const matchingLogVariable = vesselLogVariables.find(vlv => vlv.id === logVariable.id);

            logVariable.displayName = matchingLogVariable?.displayName ?? logVariable.name;
            Vue.set(columnLogVariables, y, logVariable);
          }
        }
      }
    }
  }

  getVesselLogVariables(vesselId: number): LogVariable[] | undefined {
    if (!vesselId) return;
    return LogVariables.vesselsLogVariables.find(vessel => vessel.vesselId === vesselId)?.logVariables;
  }

  addColumn(): void {
    const column: VesselDataWidgetColumn = {
      name: "",
      hours: 0,
      fromDate: "",
      toDate: "",
      calculationType: "",
      logVariables: [],
      cachedResults: [],
      sequence: this.config.columns.length ? this.config.columns.length : 0,
    };
    this.config.columns.push(column);
  }

  onLogVariableFieldClick(item: { vessel: ExtendedVessel; index: number }): void {
    this.selectedVessel = item.vessel;
    this.selectedColumnIndex = item.index;
    this.isLogVariablesModalActive = true;
  }

  async onModalOpen(): Promise<void> {
    // eslint-disable-next-line
    for (const vessel of this.config.vessels.values()) {
      if (!this.isVesselLogVariablesLoaded(vessel.id)) {
        await LogVariables.fetchAllLogVariablesByVesselId(vessel.id);
      }
    }

    await this.initDisplayNameOfConfig();
  }

  onModalClose(): void {
    this.closeEditDialog();
  }

  closeEditDialog(): void {
    this.isModalActive = false;
    //  reset selected vessels to initial state after modal is closed
    setTimeout(() => {
      this.config = JSON.parse(JSON.stringify(this.initialConfig));
    }, 100);
  }

  async onSaveWidgetName(name: string): Promise<void> {
    await Dash.updateWidget({ id: this.widgetRef.id, name });
  }

  async initWidgetData(): Promise<void> {
    try {
      this.isDataLoading = true;
      this.config = await VesselDataWidgetConfiguration.getVesselDataWidgetConfigById(this.widgetRef.configId);
      this.initialConfig = JSON.parse(JSON.stringify(await this.fetchCalculatedLogData(this.config)));
    } catch (error) {
      console.warn("Could not find config for table widget: ", error);
    }
    this.isDataLoading = false;
  }

  onColumnDelete(index: number): void {
    this.config.columns.splice(index, 1);
    //  update columns sequence
    VesselDataWidgetConfiguration.updateVesselDataWidgetConfigColumnsSequence(this.config.id);
    this.config.columns.forEach((column, i) => (column.sequence = i));
  }

  async created(): Promise<void> {
    if (Vessels.extendedVesselsExpired) await Vessels.refreshExtendedVessels();
    await this.initWidgetData();
  }
}
