


















































import { Component, Vue, PropSync, Watch } from "vue-property-decorator";
import store from "@/store";
//  utilities
import itemMixin from "@/mixins/itemMixin.js";
import dateHelper from "Utilities/date-helper";
import numberHelper from "Utilities/number-helper";
import stringifiedCalculationMethodForHeader from "./VDataTableCalculationMethodHeaders";
//  types
import { VesselDataWidgetColumn } from "@/types/VesselDataWidgetColumn";
import { VesselDataWidgetConfig as VesselDataConfig } from "@/types/VesselDataWidgetConfig";

//  modules
import { getModule } from "vuex-module-decorators";
import VesselDataWidgetConfiguration from "@/store/clients/VesselDataWidgetConfig.module";

const VesselDataWidgetConfig = getModule(VesselDataWidgetConfiguration, store);

interface TableHeader {
  text: string;
  value: string;
  sortable: boolean;
  subHeaderText: string;
  subHeaderTooltipText: string;
}
@Component({
  mixins: [itemMixin],
  components: {},
})
export default class VesselDataTable extends Vue {
  @PropSync("data", { required: true, type: Object }) initialConfig!: VesselDataConfig;
  @PropSync("loading", { type: Boolean, default: false }) isDataLoading!: boolean;

  @Watch("vesselDataWidgetConfigs")
  onVesselDataWidgetConfigsUpdated(): void {
    this.isDataLoading = true;
    const updatedConfig = VesselDataWidgetConfig.getConfigById(this.initialConfig.id);
    if (updatedConfig) Object.assign(this.initialConfig, updatedConfig);
    setTimeout(() => (this.isDataLoading = false), 300);
  }

  get tableHeaders(): TableHeader[] {
    const tableHeaders = this.initialConfig.columns.map((column: VesselDataWidgetColumn, index: number) => {
      const hours = dateHelper.getDatesDiffAsHours(column.fromDate, column.toDate);
      const subHeader = stringifiedCalculationMethodForHeader(column.calculationType, hours, column?.fromAndToDatesCalculationType as string, column.fromDate, column.toDate);
      return {
        text: column.name,
        sortable: true,
        value: `column${++index}`,
        subHeaderText: subHeader.text,
        subHeaderTooltipText: subHeader.tooltipText,
      };
    });

    if (tableHeaders.length) {
      tableHeaders.unshift({
        text: "Vessel name",
        value: "name",
        sortable: true,
        subHeaderText: "",
        subHeaderTooltipText: "",
      });
    }

    return tableHeaders;
  }

  get tableRowItems(): any[] {
    const tableRowItems = this.initialConfig.vessels.map((item: any) => {
      const tableRow: any = { column0: item.name, name: item.name };
      this.initialConfig.columns.forEach((column: VesselDataWidgetColumn, index: number) => {
        const columnKey = `column${++index}`;
        const matchedValue = column.cachedResults.find((variable: any) => variable.vesselId === item.id);
        tableRow[columnKey] = this.stringifyResult(matchedValue);
      });
      return tableRow;
    });

    return tableRowItems;
  }

  get vesselDataWidgetConfigs(): VesselDataConfig[] {
    return VesselDataWidgetConfig.vesselDataWidgetConfigs;
  }

  customSort(items: [], sortBy: any, isDesc: any): [] {
    //do nothing when the sorting is reset
    if (!sortBy[0]) return items;

    //sort first column as strings
    if (sortBy[0] === "name") {
      return items.sort((a: any, b: any) => {
        if (isDesc[0]) return b[sortBy] < a[sortBy] ? -1 : 1;

        return a[sortBy] < b[sortBy] ? -1 : 1;
      });
    }

    //sort all other columns as numbers
    return items.sort((a: any, b: any) => {
      const formatedA = numberHelper.parseLocaleNumber(a[sortBy]);
      const formatedB = numberHelper.parseLocaleNumber(b[sortBy]);

      return isDesc[0] ? ((Number(formatedB) || 0) < (Number(formatedA) || 0) ? -1 : 1) : (Number(formatedA) || 0) < (Number(formatedB) || 0) ? -1 : 1;
    });
  }

  stringifyResult(value: any): string {
    if (!value || value.result === 0) return "—";
    if (value.result === "NaN") return "No data";
    return `${new Intl.NumberFormat(undefined, { maximumFractionDigits: value.logVariableDisplayPrecision }).format(value.result)} ${value.unitCaption}`;
  }
}
