





















































































































import { Component, Vue, Prop, PropSync, Watch } from "vue-property-decorator";
// Highcharts library
import { Chart } from "highcharts-vue";
//  types
import { CiiData } from "@/types/ciiData";
import { Vessel } from "@/types/Vessel";

@Component({
  components: {
    Highcharts: Chart,
  },
})
export default class ChartView extends Vue {
  @PropSync("data", { type: Array, default: [] }) ciiData!: CiiData[];
  @PropSync("loading", { type: Boolean, default: false }) isDataLoading!: boolean;
  @PropSync("noDataVessels", { type: Array, default: [] }) vesselsNoDataList!: Vessel[];
  @Prop() resizing!: boolean;

  chart!: any;
  chartLoaded = false;
  reflowTimer!: number;
  //  need to call reflow on the chart with some delay after widget resize, otherwise it will not resize properly

  @Watch("resizing")
  resized(val: boolean): void {
    this.reflowTimer = setTimeout(() => {
      if (!val && this.chart) {
        this.chart.donutTitle.element.remove();
        this.chart.donutTitle = undefined;
        this.chart.update(this.ChartOptions, true);
        this.chart.reflow();
      }
    }, 1000);
  }

  @Watch("ciiData")
  onCiiDataUpdate(): void {
    if (this.chart.donutTitle) this.chart.donutTitle.element.remove();
    this.chart.donutTitle = undefined;
    this.chart.update(this.ChartOptions, true);
  }

  get chartSettings(): any {
    if (!this.chartLoaded) return {};
    const ctx: any = this;
    return {
      type: "pie",
      spacingTop: 0,
      spacingBottom: 0,
      spacingLeft: 0,
      spacingRight: 0,
      // marginLeft: -120,
      style: {
        fontFamily: "Helvetica Neue",
      },
      events: {
        render: function () {
          //  create and set chart average rating title in center
          //  runs after delay to be sure that chart completely redrawn
          setTimeout(() => {
            const $this: any = this;
            if (!ctx.averageRating) return;
            const left = $this.plotWidth / 2 + $this.plotLeft;
            const top = $this.plotHeight / 2 + $this.plotTop - 35;
            if (!$this.donutTitle) {
              $this.donutTitle = $this.renderer.text(ctx.title, left, top, true).attr({ align: "center" }).css({ zIndex: 5 }).addClass("chart__title").add();
            }
            $this.donutTitle.attr({
              x: left,
              y: top,
            });
          }, 500);

          //  show tooltip on legend hover event
          const legend = ctx.chart.legend;
          const legendsAmount = legend.allItems.length;
          for (let i = 0; i < legendsAmount; i++) {
            (function (i) {
              const item = legend.allItems[i].legendItem;
              item.on("mouseover", function (event: any) {
                if (ctx.chart.series[0].data[i].y) {
                  ctx.chart.tooltip.refresh(ctx.chart.series[0].data[i]);
                }
              });
            })(i);
          }
          setTimeout(() => ctx.chart.reflow(), 100);
        },
      },
    };
  }
  get ChartOptions(): any {
    if (!this.chartLoaded) return {};
    const ctx = this;
    const options = {
      chart: ctx.chartSettings,
      title: {
        text: "",
      },
      legend: {
        useHTML: true,
        align: "right",
        verticalAlign: "middle",
        layout: "vertical",
        x: 0,
        symbolPadding: 0,
        symbolWidth: 0.1,
        symbolHeight: 0.1,
        symbolRadius: 0,
        itemStyle: {
          color: "#000",
          fontWeight: "bold",
          fontSize: "1.7rem",
        },
        labelFormatter: function () {
          const $this: any = this;
          return `
                    <div class="cii-donut__label ${$this.y === 0 ? "cii-donut__label--novalue" : ""}">
                      <div
                        class="cii-donut__label__icon cii-donut__label__icon--${$this.name}"
                        style="background-color: ${$this.color}"
                      >
                        ${$this.name}
                      </div>
                      <div
                        class="cii-donut__label__text">${$this.y}</div>
                    </div>
                  `;
        },
      },
      plotOptions: {
        pie: {
          innerSize: "60%",
          shadow: false,
          borderWidth: 1,
          point: {
            events: { legendItemClick: () => false },
          },
        },
        series: {
          showInLegend: true,
          dataLabels: { enabled: false },
        },
      },
      tooltip: {
        useHTML: true,
        formatter: function () {
          const $this: any = this;
          let html = "";
          const vesselsCiiDataList = ctx.filterVesselsByRating($this.point.name);
          vesselsCiiDataList.forEach(cii => {
            html = html.concat(`
                      <div class="cii-donut__tooltip-item">
                        <span class="name">${cii.vessel.name}:</span><span class="value">${cii.attainedCii.toFixed(1)} CO2g/nm</span>
                      </div>
                    `);
          });
          return html;
        },
        animation: false,
        backgroundColor: "rgba(0, 0, 0, 0)",
        borderRadius: 15,
        borderWidth: 0,
        shadow: false,
        // outside: true,
        style: {
          color: "#fff",
          fontSize: "12px",
          zIndex: 100,
        },
      },
      series: [
        {
          data: this.seriesData,
        },
      ],
      credits: { enabled: false },
      exporting: { enabled: false },
    };

    return options;
  }

  get averageAttainedCii(): number | string {
    if (!this.ciiData.length) return "No Data";
    const averageAttainedCii = this.ciiData.reduce((acc: number, item: CiiData) => acc + item.attainedCii, 0) / this.ciiData.length;
    return new Intl.NumberFormat(undefined, { maximumFractionDigits: 1 }).format(averageAttainedCii);
  }

  get totalEmittedCO2(): string | undefined {
    if (!this.ciiData.length) return;
    const totalEmittedCO2 = this.ciiData.reduce((acc: number, item: CiiData) => acc + item.co2EmissionsInGrams, 0) / 1000000;
    return new Intl.NumberFormat(undefined, { maximumFractionDigits: 1 }).format(totalEmittedCO2);
  }

  get totalDistanceTravelled(): string | undefined {
    if (!this.ciiData.length) return;
    const totalDistanceTravelled = this.ciiData.reduce((acc: number, item: CiiData) => acc + item.distanceTravelledInNm, 0);
    return new Intl.NumberFormat(undefined, { maximumFractionDigits: 1 }).format(totalDistanceTravelled);
  }

  filterVesselsByRating(rating: string): CiiData[] {
    return this.ciiData.filter(item => item.ciiRating === rating);
  }

  get seriesData(): { name: string; y: number; color: string }[] {
    return [
      { name: "A", y: this.filterVesselsByRating("A").length, color: "#33a357" },
      { name: "B", y: this.filterVesselsByRating("B").length, color: "#c3d545" },
      { name: "C", y: this.filterVesselsByRating("C").length, color: "#fff12c" },
      { name: "D", y: this.filterVesselsByRating("D").length, color: "#edb731" },
      { name: "E", y: this.filterVesselsByRating("E").length, color: "#cc232a" },
    ];
  }

  get averageRating(): { grade: string; color: string } {
    const ratingsAmount = this.seriesData.reduce((acc, series) => acc + series.y, 0);
    let a = 0,
      b = 0,
      c = 0,
      d = 0,
      e = 0;
    this.seriesData.forEach(series => {
      switch (series.name) {
        case "A":
          a = series.y * 5;
        case "B":
          b = series.y * 4;
        case "C":
          c = series.y * 3;
        case "D":
          d = series.y * 2;
        case "E":
          e = series.y * 1;
      }
    });
    const averageGrade = (a + b + c + d + e) / ratingsAmount;
    return this.letterGrade(averageGrade);
  }

  letterGrade(grade: number): { grade: string; color: string } {
    let letterGrade!: { grade: string; color: string };
    if (grade <= 1) {
      letterGrade = { grade: "E", color: "#cc232a" };
    } else if (grade <= 2) {
      letterGrade = { grade: "D", color: "#edb731" };
    } else if (grade <= 3) {
      letterGrade = { grade: "C", color: "#fff12c" };
    } else if (grade <= 4) {
      letterGrade = { grade: "B", color: "#c3d545" };
    } else if (grade <= 5 || grade > 5) {
      letterGrade = { grade: "A", color: "#33a357" };
    }
    return letterGrade;
  }

  get title(): string {
    // if (!this.averageRating) return "";
    return `
            <div
              class="cii-donut__title"
              style="background-color: ${this.averageRating.color}"
            >
              ${this.averageRating.grade}
              <span class="cii-donut__title__hint">Fleet average rating</span>
            </div>
            `;
  }

  chartReady(chart: any): void {
    this.chart = chart;
    this.chart.update(this.ChartOptions, true);
    this.chartLoaded = true;
  }

  get Highchart(): any {
    return this.chart;
  }

  onWindowResize(): void {
    setTimeout(() => {
      this.chart.reflow();
    }, 200);
  }

  created(): void {
    window.addEventListener("resize", this.onWindowResize);
  }

  beforeDestroy(): void {
    if (this.reflowTimer) clearTimeout(this.reflowTimer);
    window.removeEventListener("resize", this.onWindowResize);
  }
}
