
export interface TableHeader {
  text: string | undefined;
  value: string;
  sortable?: boolean;
  width?: string;
}

export interface TableRow {
  imoNumber: number;
  vesselName: string;
  deadweight: number | string | null;
  distanceTravelledInNm: number;
  co2EmissionsInGrams: number;
  attainedCii: number;
  referenceLine: number;
  requiredCii: number;
  ciiRating: string;
  ciiTrend?: {
    penultimate7DaysAverage: number;
    last7DaysAverage: number;
  }
}
export const headers: TableHeader[] = [
  {
    text: "Vessel Name",
    value: "vesselName",
    sortable: true,
    width: "159px",
  },
  {
    text: "IMO",
    value: "imoNumber",
    sortable: true,
    width: "94px",
  },
  {
    text: "Deadweight / <br>Gross Tonnage",
    value: "deadweight",
    sortable: true,
    width: "154px",
  },
  {
    text: "Distance travelled<br><span class='table-subheader'>nm<span>",
    value: "distanceTravelledInNm",
    sortable: true,
    width: "177px",
  },
  {
    text: "CO2 emitted<br><span class='table-subheader'>ton<span>",
    value: "co2EmissionsInGrams",
    sortable: true,
    width: "157px",
  },
  {
    text: "Required CII <br><span class='table-subheader'>CO2g/nm<span>",
    value: "requiredCii",
    sortable: true,
    width: "178px",
  },
  {
    text: "Attained CII <br><span class='table-subheader'>CO2g/nm<span>",
    value: "attainedCii",
    sortable: true,
    width: "133px",
  },
  {
    text: "CII Trend <br><span class='table-subheader'>(last 7 days)<span>",
    value: "ciiTrend",
    width: "131px",
    sortable: false,
  },
  {
    text: "CII Rating",
    value: "ciiRating",
    sortable: true,
    width: "113px",
  },
];