


































































































































import { Component, Ref, Vue, Prop } from "vue-property-decorator";
import store from "@/store";
import itemMixin from "@/mixins/itemMixin.js";
// modules
import { getModule } from "vuex-module-decorators";
import VesselsModule from "@/store/clients/Vessels.module";
import DashboardModule from "@/store/clients/Dashboard.module";
import IncidentsModule from "@/store/clients/Incidents.module";
import NotificationWidgetModule from "@/store/clients/NotificationWidget.module";
import UserModule from "@/store/clients/User.module";
import SnackbarModule from "@/store/clients/Snackbar.module";
// types
import { Widget } from "@/types/widget";
import { TableRow } from "@/types/widgetNotification";
import { ExtendedVessel } from "@/types/Vessel";
import { Incident } from "@/types/Incident";
import { Condition } from "@/types/condition";
import { NotificationWidgetConfig } from "@/types/widgetNotification";
import { tableHeaders } from "./VTableData";
// components
import WidgetEditDialog from "@/components/widgets/WidgetEditDialog.vue";
import VesselSelect from "@/components/VesselSelect.vue";
import LoadingTextWave from "@/components/LoadingTextWave.vue";
import WarningTooltip from "./WarningTooltip.vue";
import CriticalTooltip from "./CriticalTooltip.vue";

const Vessels = getModule(VesselsModule, store);
const Dash = getModule(DashboardModule, store);
const Incidents = getModule(IncidentsModule, store);
const NotificationWidgetConfiguration = getModule(NotificationWidgetModule, store);
const User = getModule(UserModule, store);
const Snackbar = getModule(SnackbarModule, store);

@Component({
  mixins: [itemMixin],
  components: {
    WidgetEditDialog,
    VesselSelect,
    LoadingTextWave,
    WarningTooltip,
    CriticalTooltip,
  },
})
export default class NotificationWidget extends Vue {
  @Prop() widgetRef!: Widget;
  @Ref("WidgetEditDialog") WidgetEditDialog!: WidgetEditDialog;

  config!: NotificationWidgetConfig;
  tableHeaders = tableHeaders;
  selectedVessels: ExtendedVessel[] = [];
  initialSelectedVessels: ExtendedVessel[] = [];
  conditions: Condition[] = [];
  isModalActive = false;
  isDataLoading = false;

  get isPartOfSharedDashboard(): boolean {
    return Dash.activeDashboard.userId !== User.userId;
  }

  get extendedVessels(): ExtendedVessel[] {
    return Vessels.extendedVessels;
  }

  get tableRows(): TableRow[] {
    const tableRows: TableRow[] = [];

    this.initialSelectedVessels.forEach(vessel => {
      const tableRow: TableRow = {
        id: vessel.id,
        name: vessel.name,
        notificationModuleEnabled: vessel.features.some(feature => feature.name === "Notifications"),
        activeConditions: this.conditions.filter(condition => condition.vesselId === vessel.id && condition.isDeleted === false).length,
        warningIncidents: {
          amount: this.openWarningIncidents(vessel.id).length,
          list: this.openWarningIncidents(vessel.id),
        },
        criticalIncidents: {
          amount: this.openCriticalIncidents(vessel.id).length,
          list: this.openCriticalIncidents(vessel.id),
        },
      };

      tableRows.push(tableRow);
    });

    return tableRows;
  }

  async saveConfig(): Promise<void> {
    this.isDataLoading = true;
    try {
      await this.fetchConditionsForEachVessel(this.selectedVessels);
      await NotificationWidgetConfiguration.updateConfig({ ...this.config, vessels: this.selectedVessels });
      //  re-assign config after update
      this.config = await NotificationWidgetConfiguration.getConfigById(this.widgetRef.configId);
      this.initialSelectedVessels = JSON.parse(JSON.stringify(this.config.vessels));
      Snackbar.showSnackbar({ text: "Notification widget config successfully updated", color: "success" });
    } catch (error) {
      Snackbar.showSnackbar({ text: "Failed to update Notification widget config" });
    }
    this.isDataLoading = false;
    this.isModalActive = false;
  }

  async fetchConditionsForEachVessel(selectedVessels: ExtendedVessel[]): Promise<void> {
    for (const vessel of selectedVessels) {
      const vesselConditions = await Incidents.fetchConditionsByVesselId(vessel.id);
      this.conditions = [...this.conditions, ...vesselConditions];
    }
  }

  openWarningIncidents(vesselId: number): Incident[] {
    return Incidents.distinctAllOpenIncidents.filter(incident => incident.severity === "Warning" && incident.vesselId === vesselId);
  }

  openCriticalIncidents(vesselId: number): Incident[] {
    return Incidents.distinctAllOpenIncidents.filter(incident => incident.severity === "Critical" && incident.vesselId === vesselId);
  }

  onModalClose(): void {
    this.closeEditDialog();
  }

  closeEditDialog(): void {
    this.isModalActive = false;
    //  reset selected vessels to initial state after modal is closed
    setTimeout(() => {
      this.selectedVessels = this.initialSelectedVessels;
    }, 100);
  }

  async onSaveWidgetName(name: string): Promise<void> {
    await Dash.updateWidget({ id: this.widgetRef.id, name });
  }

  async createNewConfig(): Promise<void> {
    try {
      const newConfig = await NotificationWidgetConfiguration.addNewConfig();
      Dash.updateWidget({ id: this.widgetRef.id, configId: newConfig.id });
      Snackbar.showSnackbar({ text: "Config for this widget could not be found, so a new one has been created", color: "primary" });
    } catch (error) {
      console.error(error);
    }
  }

  async initWidgetData(): Promise<void> {
    try {
      this.config = await NotificationWidgetConfiguration.getConfigById(this.widgetRef.configId);
      this.initialSelectedVessels = JSON.parse(JSON.stringify(this.config.vessels));
      this.selectedVessels = this.config.vessels;
      this.fetchConditionsForEachVessel(this.initialSelectedVessels);
    } catch (error) {
      console.warn(error);
      await this.createNewConfig();
    }
  }

  async created(): Promise<void> {
    if (Vessels.extendedVesselsExpired) await Vessels.refreshExtendedVessels();
    await this.initWidgetData();
  }
}
