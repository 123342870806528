




























































































































































































































































import { Component, Ref, Vue, Prop } from "vue-property-decorator";
import store from "@/store";
import itemMixin from "@/mixins/itemMixin.js";
import performanceStatusHelper from "Utilities/performance-status-helper";
// modules
import { getModule } from "vuex-module-decorators";
import VesselsModule from "@/store/clients/Vessels.module";
import PerformanceStatusWidgetModule from "@/store/clients/PerformanceStatusWidget.module";
import DashboardModule from "@/store/clients/Dashboard.module";
import UserModule from "@/store/clients/User.module";
import SnackbarModule from "@/store/clients/Snackbar.module";
// types
import { Widget } from "@/types/widget";
import { PerformanceStatusI, TableRow } from "@/types/widgetPerformanceStatus";
import { ExtendedVessel } from "@/types/Vessel";
import { tableHeaders } from "./VTableData";
// components
import WidgetEditDialog from "@/components/widgets/WidgetEditDialog.vue";
import VesselSelect from "@/components/VesselSelect.vue";
import StatusTooltip from "./StatusTooltip.vue";
import LoadingTextWave from "@/components/LoadingTextWave.vue";
import { PerformanceStatusConfig } from "@/types/widgetPerformanceStatus";

const PerfStatus = getModule(PerformanceStatusWidgetModule, store);
const Vessels = getModule(VesselsModule, store);
const Dash = getModule(DashboardModule, store);
const User = getModule(UserModule, store);
const Snackbar = getModule(SnackbarModule, store);

@Component({
  mixins: [itemMixin],
  components: {
    WidgetEditDialog,
    VesselSelect,
    StatusTooltip,
    LoadingTextWave,
  },
})
export default class PerformanceStatus extends Vue {
  @Prop() widgetRef!: Widget;
  @Ref("WidgetEditDialog") WidgetEditDialog!: WidgetEditDialog;

  config!: PerformanceStatusConfig;
  tableHeaders = tableHeaders;
  selectedVessels: ExtendedVessel[] = [];
  initialSelectedVessels: ExtendedVessel[] = [];
  isModalActive = false;
  isDataLoading = false;

  get isPartOfSharedDashboard(): boolean {
    return Dash.activeDashboard.userId !== User.userId;
  }

  get extendedVessels(): ExtendedVessel[] {
    return Vessels.extendedVessels;
  }

  get tableRows(): TableRow[] {
    const tableRows: TableRow[] = [];

    this.initialSelectedVessels.forEach(vessel => {
      const tableRow: TableRow = {
        id: vessel.id,
        name: vessel.name,
        rpm: { statusIcon: "", subStatuses: [] },
        sfr: { statusIcon: "", subStatuses: [] },
        speed: { statusIcon: "", subStatuses: [] },
      };

      if (vessel.isDiagnosticActivated) {
        if (vessel.longTrends.length) {
          vessel.longTrends.some(longTrend => {
            if ((longTrend as any).diagnosticType) {
              const status: PerformanceStatusI = {
                name: (longTrend as any).descriptionLong,
                statusCode: (longTrend as any).performanceStatus,
                statusMessage: this.formatStatusCode((longTrend as any).performanceStatus),
                statusIcon: performanceStatusHelper.getPerformanceStatusImageSrc((longTrend as any).performanceStatus),
                statusUpdated: (longTrend as any).performanceStatusUpdated,
              };

              switch ((longTrend as any).diagnosticType.name) {
                case "RPM":
                  tableRow.rpm.subStatuses?.push(status);
                  break;
                case "SFR":
                  tableRow.sfr.subStatuses?.push(status);
                  break;
                case "Speed":
                  tableRow.speed.subStatuses?.push(status);
                  break;
              }
            } else {
              return;
            }
          });
        }
      }

      tableRows.push(this.setCombinedStatuses(tableRow));
    });

    return tableRows;
  }

  async saveConfig(): Promise<void> {
    this.isDataLoading = true;
    try {
      await PerfStatus.updateConfig({ ...this.config, vessels: this.selectedVessels });
      //  re-assign config after update
      this.config = await PerfStatus.getConfigById(this.widgetRef.configId);
      this.initialSelectedVessels = JSON.parse(JSON.stringify(this.config.vessels));
      Snackbar.showSnackbar({ text: "Performance status widget config successfully updated", color: "success" });
    } catch (error) {
      Snackbar.showSnackbar({ text: "Failed to update Performance status widget config" });
    }
    this.isDataLoading = false;
    this.isModalActive = false;
  }

  //set the combined status for each column table row
  setCombinedStatuses(row: TableRow): TableRow {
    row.rpm.statusIcon = this.calculateCombinedStatus(row.rpm.subStatuses);
    row.sfr.statusIcon = this.calculateCombinedStatus(row.sfr.subStatuses);
    row.speed.statusIcon = this.calculateCombinedStatus(row.speed.subStatuses);

    return row;
  }

  //calculate the combined status for the status "category" (rpm, sfr or speed)
  calculateCombinedStatus(statuses: PerformanceStatusI[]): any {
    let combinedStatus = "DiagnosticsNotActivated";

    statuses.forEach(status => {
      if (performanceStatusHelper.getSortValueByStatus(status.statusCode) < performanceStatusHelper.getSortValueByStatus(combinedStatus)) {
        combinedStatus = status.statusCode;
      }
    });

    return performanceStatusHelper.getPerformanceStatusImageSrc(combinedStatus);
  }

  formatStatusCode(statusCode: string): string {
    switch (statusCode) {
      case "NotOk":
        return "Not ok";
      case "BenchMarking":
        return "Benchmarking";
      default:
        return statusCode;
    }
  }

  onModalClose(): void {
    this.closeEditDialog();
  }

  closeEditDialog(): void {
    this.isModalActive = false;
    //  reset selected vessels to initial state after modal is closed
    setTimeout(() => {
      this.selectedVessels = this.initialSelectedVessels;
    }, 100);
  }

  async onSaveWidgetName(name: string): Promise<void> {
    await Dash.updateWidget({ id: this.widgetRef.id, name });
  }

  async createNewConfig(): Promise<void> {
    try {
      const newConfig = await PerfStatus.addNewConfig();
      Dash.updateWidget({ id: this.widgetRef.id, configId: newConfig.id });
      Snackbar.showSnackbar({ text: "Config for this widget could not be found, so a new one has been created", color: "primary" });
    } catch (error) {
      console.error(error);
    }
  }

  async initWidgetData(): Promise<void> {
    try {
      this.config = await PerfStatus.getConfigById(this.widgetRef.configId);
      this.initialSelectedVessels = JSON.parse(JSON.stringify(this.config.vessels));
      this.selectedVessels = this.config.vessels;
    } catch (error) {
      console.warn(error);
      await this.createNewConfig();
    }
  }

  async created(): Promise<void> {
    if (Vessels.extendedVesselsExpired) await Vessels.refreshExtendedVessels();
    await this.initWidgetData();
  }
}
