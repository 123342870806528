var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{staticClass:"dashboard__widget widget__fouling-overview",class:_vm.moving ? 'isMoving' : '',attrs:{"elevation":_vm.moving ? 15 : 4,"width":_vm.width,"height":_vm.height}},[_c('v-toolbar',{attrs:{"color":"neutralLighten100","elevation":"0","dense":""}},[_c('v-icon',{staticClass:"mr-2",attrs:{"color":"primaryLighten80"}},[_vm._v("mdi-ferry")]),_c('v-toolbar-title',[_c('div',{staticClass:"d-flex flex-column"},[_vm._v(_vm._s(_vm.widgetRef.name))])]),_c('v-spacer'),_c('v-tooltip',{attrs:{"top":"","color":"primary lighten-2"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('div',_vm._g({},on),[_c('v-btn',{attrs:{"disabled":_vm.isPartOfSharedDashboard,"loading":_vm.isDataLoading,"color":"primaryLighten80","icon":""},on:{"click":function($event){return _vm.WidgetEditDialog.open(_vm.widgetRef.name)}}},[_c('v-icon',[_vm._v("edit")])],1)],1)]}}])},[(_vm.isPartOfSharedDashboard)?_c('span',[_vm._v("Can not edit shared dashboard")]):_c('span',[_vm._v("Edit widget configuration")])])],1),(_vm.isDataLoading)?_c('LoadingTextWave',{staticClass:"mt-10"}):_c('section',{staticClass:"widget-content"},[_c('v-card-text',{staticClass:"px-0 py-0"},[_c('v-data-table',{attrs:{"headers":_vm.tableHeaders,"items":_vm.tableRows,"loading":_vm.isDataLoading,"dense":"","loading-text":"Loading... Please wait","no-data-text":"Click the edit-icon in the top right corner to start adding vessels","height":"100%","fixed-header":"","hide-default-footer":"","disable-pagination":""},scopedSlots:_vm._u([{key:"header.name",fn:function(ref){
var header = ref.header;
return [_c('span',{staticClass:"primary--text"},[_vm._v(" "+_vm._s(header.text)+" ")])]}},{key:"header.foulingStatus",fn:function(ref){
var header = ref.header;
return [_c('span',{staticClass:"primary--text"},[_vm._v(" "+_vm._s(header.text)+" ")])]}},{key:"header.speedLoss",fn:function(ref){
var header = ref.header;
return [_c('span',{staticClass:"primary--text"},[_vm._v(" "+_vm._s(header.text)+" ")])]}},{key:"header.cumulativeAddedConsumption",fn:function(ref){
var header = ref.header;
return [_c('span',{staticClass:"primary--text",domProps:{"innerHTML":_vm._s(header.text)}})]}},{key:"header.hullCoating",fn:function(ref){
var header = ref.header;
return [_c('span',{staticClass:"primary--text"},[_vm._v(" "+_vm._s(header.text)+" ")])]}},{key:"item",fn:function(ref){
var item = ref.item;
return [_c('table-record',{attrs:{"vesselId":item.id,"vesselName":item.name}})]}}],null,true)})],1)],1),_c('WidgetEditDialog',{ref:"WidgetEditDialog",attrs:{"value":_vm.isModalActive},on:{"update:value":function($event){_vm.isModalActive=$event},"close":_vm.onModalClose,"onSaveWidgetName":_vm.onSaveWidgetName},scopedSlots:_vm._u([{key:"content",fn:function(){return [_c('VesselSelect',{attrs:{"vesselsList":_vm.extendedVessels,"selectedVesselsList":_vm.selectedVessels,"requiredFeatures":['Dashboard', 'Fouling']},on:{"update:selectedVesselsList":function($event){_vm.selectedVessels=$event},"update:selected-vessels-list":function($event){_vm.selectedVessels=$event}}})]},proxy:true},{key:"actions",fn:function(){return [_c('v-spacer'),_c('v-btn',{staticClass:"font-weight-semibold",attrs:{"text":"","color":"primary"},on:{"click":function($event){return _vm.closeEditDialog()}}},[_vm._v(" Cancel ")]),_c('v-btn',{staticClass:"font-weight-semibold",attrs:{"color":"accent"},on:{"click":function($event){return _vm.saveConfig()}}},[_vm._v(" Save ")])]},proxy:true}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }