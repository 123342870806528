<template>
  <div class="loading-text d-flex justify-center">
    <div>L</div>
    <div>O</div>
    <div>A</div>
    <div>D</div>
    <div>I</div>
    <div>N</div>
    <div>G</div>
    <div>.</div>
    <div>.</div>
    <div>.</div>
  </div>
</template>

<script>
  import { Component, Vue } from "vue-property-decorator";

  @Component({})
  export default class LoadingTextWave extends Vue {}
</script>


<style scoped lang="scss">
  .loading-text {
    div {
      -webkit-animation: waveAnimation 2s ease-in-out infinite; /* Safari 4+ */
      -moz-animation: waveAnimation 2s ease-in-out infinite; /* Fx 5+ */
      -o-animation: waveAnimation 2s ease-in-out infinite; /* Opera 12+ */
      animation: waveAnimation 2s ease-in-out infinite; /* IE 10+, Fx 29+ */
      display: inline-flex;
      justify-content: center;
      align-items: center;
      margin: 5px;
    }

    :nth-child(0) {
      animation-delay: 0s;
    }
    :nth-child(1) {
      animation-delay: 0.1s;
    }
    :nth-child(2) {
      animation-delay: 0.2s;
    }
    :nth-child(3) {
      animation-delay: 0.3s;
    }
    :nth-child(4) {
      animation-delay: 0.4s;
    }
    :nth-child(5) {
      animation-delay: 0.5s;
    }
    :nth-child(6) {
      animation-delay: 0.6s;
    }
    :nth-child(7) {
      animation-delay: 0.7s;
    }
    :nth-child(8) {
      animation-delay: 0.8s;
    }
    :nth-child(9) {
      animation-delay: 0.9s;
    }
    :nth-child(10) {
      animation-delay: 1s;
    }
  }

  @keyframes waveAnimation {
    0% {
      transform: translateY(0);
    }
    20% {
      transform: translateY(-10px);
    }
    40% {
      transform: translateY(0);
    }
  }
</style>
