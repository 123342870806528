import { render, staticRenderFns } from "./WarningTooltip.vue?vue&type=template&id=2c760947&scoped=true&"
import script from "./WarningTooltip.vue?vue&type=script&lang=ts&"
export * from "./WarningTooltip.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2c760947",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBadge } from 'vuetify/lib/components/VBadge';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VTooltip } from 'vuetify/lib/components/VTooltip';
installComponents(component, {VBadge,VDivider,VIcon,VTooltip})
