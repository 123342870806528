





























































































































































































































































































import { Component, Vue, Ref, Prop, Watch } from "vue-property-decorator";
import store from "@/store";
//  helpers
import itemMixin from "@/mixins/itemMixin.js";
import moment from "moment";
//  types
import { CiiWidgetConfig } from "@/types/ciiWidgetConfig";
import { CiiData } from "@/types/ciiData";
import { Widget } from "@/types/widget";
import { ExtendedVessel, Vessel } from "@/types/Vessel";
//  components
import WidgetEditDialog from "@/components/widgets/WidgetEditDialog.vue";
import VesselSelect from "@/components/VesselSelect.vue";
import TableView from "./TableView.vue";
import ChartView from "./ChartView.vue";
//  modules
import { getModule } from "vuex-module-decorators";
import CiiWidgetModule from "@/store/clients/CiiWidget.module";
import DashboardModule from "@/store/clients/Dashboard.module";
import UserModule from "@/store/clients/User.module";
import CiiModule from "@/store/clients/Cii.module";
import VesselsModule from "@/store/clients/Vessels.module";
import SnackbarModule from "@/store/clients/Snackbar.module";
import dateHelper from "@/Scripts/utilities/date-helper";

const CiiWidgetConfiguration = getModule(CiiWidgetModule, store);
const Dash = getModule(DashboardModule, store);
const User = getModule(UserModule, store);
const Cii = getModule(CiiModule, store);
const Vessels = getModule(VesselsModule, store);
const Snackbar = getModule(SnackbarModule, store);

@Component({
  mixins: [itemMixin],
  components: {
    WidgetEditDialog,
    VesselSelect,
    TableView,
    ChartView,
  },
})
export default class CiiWidget extends Vue {
  @Prop() widgetRef!: Widget;
  @Ref("WidgetEditDialog") WidgetEditDialog!: WidgetEditDialog;

  selectedVessels: ExtendedVessel[] = [];
  selectedYear: number | null = null;
  yearToDate = true;
  ciiData: CiiData[] = [];
  vesselsNoDataList: Vessel[] = [];
  isDataLoading = false;
  widgetComponentType = "TableView";
  widgetViewSelectTypes = ["Table", "Chart"];
  isModalActive = false;
  resized = false;
  currentVessel: ExtendedVessel | null = null;
  vesselSettingsDialog = false;
  vesselType: { id: number; name: string } | null = null;
  deadweight: number | null = null;
  grossTonnage: number | null = null;
  isAnyVesselUpdated = false;
  config: CiiWidgetConfig = {
    id: 0,
    vessels: [],
    ytd: true,
    year: null,
    showTable: true,
    showChart: false,
  };
  initialConfig: CiiWidgetConfig = {
    id: 0,
    vessels: [],
    ytd: true,
    year: null,
    showTable: true,
    showChart: false,
  };
  componentViewTypes = [
    { component: "TableView", text: "Table" },
    { component: "ChartView", text: "Chart" },
  ];
  componentViewType = this.componentViewTypes[0];
  //  CII calculations use deadweight or gross tonnage depending on vessel type.
  //  useDeadweight is a list of vessels that require a deadweight for calculations. Other types require gross tonnage.
  useDeadweight = ["Containership", "Tanker", "LNG carrier", "Gas carrier", "Bulk carrier", "General cargo ship", "Refrigerated cargo carrier", "Combination carrier"];

  //let Highcharts know it has been resized, otherwise it won't trigger chart reflow
  //resizing is a computed property in the itemMixin
  @Watch("resizing")
  onResize(val: boolean): void {
    if (val) this.resized = val;
    setTimeout(() => (this.resized = false));
  }

  @Watch("config.ytd")
  onYearToDateChanged(newValue: boolean): void {
    if (newValue) this.config.year = null;
  }

  get isPartOfSharedDashboard(): boolean {
    return Dash.activeDashboard.userId !== User.userId;
  }

  get dateRange(): { start: string; end: string } | undefined {
    if (!this.ciiData.length) return;
    return {
      start: dateHelper.getFormatedDateString(this.ciiData[0].start),
      end: dateHelper.getFormatedDateString(this.ciiData[0].end),
    };
  }

  get extendedVessels(): ExtendedVessel[] {
    return Vessels.extendedVessels;
  }

  get vesselTypes(): { id: number; name: string }[] {
    return Vessels.vesselTypes;
  }

  get lastYears(): number[] {
    // return years until 2019 including
    const currentYear = new Date().getFullYear();
    const lastYears: number[] = [];
    // 2019 is the first year where CII calculations can be done
    const yearsUntil2019 = new Date().getFullYear() - 2019;
    for (let i = 1; i <= yearsUntil2019; i++) {
      lastYears.push(currentYear - i);
    }
    return lastYears;
  }

  onModalClose(): void {
    this.closeEditDialog();
  }

  closeEditDialog(): void {
    this.isModalActive = false;
    //  reset selected vessels to initial state after modal is closed
    setTimeout(() => {
      this.config = JSON.parse(JSON.stringify(this.initialConfig));
    }, 100);
  }

  onComponentViewTypeSelect(): void {
    this.config = { ...this.config, showTable: this.componentViewType.component === "TableView", showChart: this.componentViewType.component === "ChartView" };
  }

  async fetchVesselsCiiData(config: CiiWidgetConfig): Promise<void> {
    const vesselIds = config.vessels.map(vessel => vessel.id);
    const query: { vesselIds: string; years: string } = {
      vesselIds: vesselIds.toString(),
      years: config.year ? config.year.toString() : "",
    };
    if (!vesselIds.length) return;
    config.ytd ? (this.ciiData = await Cii.fetchYtd(query)) : (this.ciiData = await Cii.fetchYear(query));

    //  extract vessels with no data
    this.vesselsNoDataList = [];
    this.ciiData = this.ciiData.filter((item: any, i: number) => {
      if (item) return item;
      this.vesselsNoDataList.push(config.vessels[i]);
    });

    //  fetch 14 days from last export date, calculate and set CII trend for the last 7 days
    Promise.all(
      this.ciiData.map(async item => {
        Vue.set(item, "ciiTrend", await this.evaluateAndSetCiiTrend(item));
        return item;
      })
    ).then(data => {
      this.ciiData = data;
    });
  }

  async evaluateAndSetCiiTrend(ciiData: CiiData): Promise<{ penultimate7DaysAverage: number; last7DaysAverage: number }> {
    const start = moment(ciiData.vessel.lastExportDate).subtract(14, "days").format("YYYY-MM-DD");
    const end = moment(ciiData.vessel.lastExportDate).format("YYYY-MM-DD");
    const last14Days: any = await Cii.fetchDays({ vesselIds: ciiData.vesselId, start: start, end: end });

    const penultimate7Days = last14Days[0].slice(0, 7);
    const penultimate7DaysAverage = penultimate7Days.reduce((acc: number, item: CiiData) => acc + item.attainedCii, 0) / 7;
    const last7Days = last14Days[0].slice(7, 14);
    const last7DaysAverage = last7Days.reduce((acc: number, item: any) => acc + item.attainedCii, 0) / 7;

    return {
      penultimate7DaysAverage: penultimate7DaysAverage,
      last7DaysAverage: last7DaysAverage,
    };
  }

  async saveConfig(): Promise<void> {
    this.isDataLoading = true;
    try {
      await CiiWidgetConfiguration.updateCiiWidgetConfig(this.config);
      //  re-assign config after update
      this.config = await CiiWidgetConfiguration.getCiiWidgetConfigById(this.widgetRef.configId);
      this.initialConfig = JSON.parse(JSON.stringify(this.config));
      this.widgetComponentType = this.componentViewType.component;
      this.closeEditDialog();
      await this.fetchVesselsCiiData(this.config);
      Snackbar.showSnackbar({ text: "CII widget config successfully updated", color: "success" });
    } catch (error) {
      Snackbar.showSnackbar({ text: "Failed to update CII widget config" });
    }
    this.isDataLoading = false;
  }

  isDeadweightRequired(vesselType: { id: number; name: string } | null): boolean {
    if (!vesselType) return true;
    return this.useDeadweight.includes(vesselType?.name);
  }

  isVesselSettingsRequired(vessel: ExtendedVessel): boolean {
    if (!vessel) return false;

    if (vessel?.vesselType?.name == null) return true;
    else if (this.isDeadweightRequired(vessel.vesselType) && vessel.deadweight === null) return true;
    else if (!this.isDeadweightRequired(vessel.vesselType) && vessel.grossTonnage === null) return true;
    else return false;
  }

  openVesselSettingsDialog(vessel: ExtendedVessel): void {
    this.currentVessel = vessel;
    if (!this.currentVessel) return;
    this.vesselType = this.currentVessel.vesselType;
    this.deadweight = this.currentVessel.deadweight;
    this.grossTonnage = this.currentVessel.grossTonnage;
    this.vesselSettingsDialog = true;
  }

  closeVesselSettingsDialog(): void {
    this.currentVessel = null;
    this.vesselType = null;
    this.deadweight = null;
    this.grossTonnage = null;
    this.vesselSettingsDialog = false;
  }

  get isSaveButtonDisabled(): boolean {
    return Boolean(this.vesselType == null || (this.isDeadweightRequired(this.vesselType) && this.deadweight == null) || (!this.isDeadweightRequired(this.vesselType) && this.grossTonnage == null));
  }

  async submitVesselSettings(): Promise<void> {
    if (!this.currentVessel) return;
    try {
      await Vessels.updateVessel({
        id: this.currentVessel.id,
        vesselType: this.vesselType,
        deadweight: this.deadweight,
        grossTonnage: this.grossTonnage,
      });
      this.isAnyVesselUpdated = true;
      await Vessels.refreshExtendedVessels();
      this.config = {} as CiiWidgetConfig;
      this.config = await CiiWidgetConfiguration.getCiiWidgetConfigById(this.widgetRef.configId);
      this.initialConfig = JSON.parse(JSON.stringify(this.config));
      Snackbar.showSnackbar({ text: "Vessel settings successfully updated", color: "success" });
    } catch (error) {
      Snackbar.showSnackbar({ text: "Could not update vessel settings" });
    }
    this.vesselSettingsDialog = false;
  }

  async createNewConfig(): Promise<void> {
    try {
      const newConfig = await CiiWidgetConfiguration.addNewConfig();
      Dash.updateWidget({ id: this.widgetRef.id, configId: newConfig.id });
      Snackbar.showSnackbar({ text: "Config for this widget could not be found, so a new one has been created", color: "primary" });
    } catch (error) {
      console.error(error);
    }
  }

  async initWidgetData(): Promise<void> {
    this.isDataLoading = true;
    try {
      this.config = await CiiWidgetConfiguration.getCiiWidgetConfigById(this.widgetRef.configId);
      this.initialConfig = JSON.parse(JSON.stringify(this.config));
      this.componentViewType = this.config.showTable ? { component: "TableView", text: "Table" } : { component: "ChartView", text: "Chart" };
      this.widgetComponentType = this.componentViewType.component;
      await this.fetchVesselsCiiData(this.config);
    } catch (error) {
      console.warn("Could not find config for cii widget: ", error);
      await this.createNewConfig();
    }
    this.isDataLoading = false;
  }

  async onSaveWidgetName(name: string): Promise<void> {
    await Dash.updateWidget({ id: this.widgetRef.id, name });
  }

  async created(): Promise<void> {
    if (Vessels.extendedVesselsExpired) await Vessels.refreshExtendedVessels();
    await Vessels.fetchVesselTypes();
    await this.initWidgetData();
  }
}
