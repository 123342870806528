






























































































































































































































import { Component, Ref, Vue } from "vue-property-decorator";
import store from "@/store";
// types
import { WidgetType } from "@/types/widgetType";
import { Widget } from "@/types/widget";
import { DashboardI } from "@/types/dashboard";
// components
import DashboardControlDialog from "@/components/DashboardControlDialog.vue";
import WelcomeWidget from "@/components/widgets/WelcomeWidget.vue";
import MapWidget from "@/components/widgets/MapWidget.vue";
import Dashboard from "@/components/3rdPartyComponents/VueResponsiveDash/Dashboard.vue";
import DashLayout from "@/components/3rdPartyComponents/VueResponsiveDash/DashLayout.vue";
import DashItem from "@/components/3rdPartyComponents/VueResponsiveDash/DashItem.vue";
import VesselDataWidget from "@/components/widgets/VesselDataWidget/index.vue";
import PerformanceStatusWidget from "@/components/widgets/PerformanceStatus/index.vue";
import NotificationWidget from "@/components/widgets/NotificationWidget/index.vue";
import FoulingOverviewWidget from "@/components/widgets/FoulingOverviewWidget/index.vue";
import CiiWidget from "@/components/widgets/CiiWidget/index.vue";
import LoadingTextWave from "@/components/LoadingTextWave.vue";
// modules
import { getModule } from "vuex-module-decorators";
import DashboardModule from "@/store/clients/Dashboard.module";
import VesselDataWidgetConfigurationModule from "@/store/clients/VesselDataWidgetConfig.module";
import VesselsModule from "@/store/clients/Vessels.module";
import VesselGroupsModule from "@/store/clients/VesselGroups.module";
import TaxonomyModule from "@/store/clients/Taxonomy.module";
import WidgetTypesModule from "@/store/clients/WidgetTypes.module";
import PerformanceStatusWidgetModule from "@/store/clients/PerformanceStatusWidget.module";
import NotificationWidgetModule from "@/store/clients/NotificationWidget.module";
import FoulingOverviewWidgetModule from "@/store/clients/FoulingOverviewWidget.module";
import SnackbarModule from "@/store/clients/Snackbar.module";
import UserModule from "@/store/clients/User.module";
import CiiWidgetModule from "@/store/clients/CiiWidget.module";

const Taxonomy = getModule(TaxonomyModule, store);
const Vessels = getModule(VesselsModule, store);
const VesselGroups = getModule(VesselGroupsModule, store);
const Dash = getModule(DashboardModule, store);
const VesselDataWidgetConfiguration = getModule(VesselDataWidgetConfigurationModule, store);
const WidgetTypes = getModule(WidgetTypesModule, store);
const PerfStatus = getModule(PerformanceStatusWidgetModule, store);
const NotificationWidgetConfiguration = getModule(NotificationWidgetModule, store);
const FoulingOverviewWidgetConfiguration = getModule(FoulingOverviewWidgetModule, store);
const CiiWidgetConfiguration = getModule(CiiWidgetModule, store);
const Snackbar = getModule(SnackbarModule, store);
const User = getModule(UserModule, store);

interface fabButton {
  title: string;
  icon: string;
  action: string;
  active?: boolean;
  disabled?: boolean;
  tooltip?: string;
}

@Component({
  components: {
    Dashboard,
    DashLayout,
    DashItem,
    WelcomeWidget,
    MapWidget,
    DashboardControlDialog,
    VesselDataWidget,
    PerformanceStatusWidget,
    LoadingTextWave,
    NotificationWidget,
    FoulingOverviewWidget,
    CiiWidget,
  },
})
export default class DashboardView extends Vue {
  //using ref to gain access to the dashLayout child component internal properties
  @Ref("dashboardRef") dashboardRef!: Vue;
  @Ref("dashboardControlDialog") dashboardControlDialog!: DashboardControlDialog;

  fabOpen = false;
  editMode = false;
  isFabClicked = false;
  startupWidget = WidgetTypes.startup;
  loadingState = true;
  loadingTimeout = false;
  loadingError = false;
  showRedesignNotification = false;
  redesignNotificationSeen = Boolean(Vue.$cookies.get("redesignNotificationSeen2"));
  doNotShowRedesignNotificationAgain = false;

  //store getters
  get activeWidgets(): Widget[] {
    return Dash.activeWidgets;
  }

  get widgets(): Widget[] {
    return Dash.activeDashboardWidgets;
  }

  get dashboard(): DashboardI {
    return Dash.activeDashboard;
  }

  get isPersonalDashboard(): boolean {
    return Dash.activeDashboard.userId === User.userId;
  }

  get fabContent(): fabButton[] {
    return [
      {
        title: "Add/remove widgets",
        icon: "mdi-view-dashboard",
        action: "add",
        active: true,
      },
      {
        title: "Edit dashboard layout",
        icon: "mdi-gesture-swipe",
        action: "edit",
        active: !this.editMode,
        disabled: !this.activeWidgets.length,
        tooltip: "You need to add widgets before you can edit the dashboard",
      },
      {
        title: "Exit edit mode",
        icon: "logout",
        action: "exitEdit",
        active: this.editMode,
      },
    ];
  }

  get isFabInitial(): boolean {
    return !this.isFabClicked && !this.activeWidgets.length;
  }

  async created(): Promise<void> {
    /* Start a timer in order to show a message to the user if
                loading is taking longer than expected (currently 8 sec). */
    setTimeout(() => {
      if (this.loadingState) this.loadingTimeout = true;
    }, 8000);

    try {
      await Promise.all([
        Vessels.refreshVessels(),
        Vessels.extendedVesselsExpired === true ? Vessels.refreshExtendedVessels() : null,
        VesselGroups.refreshVesselGroups(),
        Taxonomy.getTaxonomyCategories(),
        Dash.initializeDashboard(),
        VesselDataWidgetConfiguration.refreshVesselDataWidgetConfigs(),
        PerfStatus.refreshConfigs(),
        NotificationWidgetConfiguration.refreshConfigs(),
        FoulingOverviewWidgetConfiguration.refreshConfigs(),
        CiiWidgetConfiguration.refreshCiiWidgetConfigs(),
      ]);
    } catch (error) {
      this.loadingError = true;
      console.error("An error occurred when loading dashboard", error);

      Snackbar.showSnackbar({
        text: "An error occurred, and the dashboard could not be loaded. <span onClick='window.location.href=window.location.href'><a  class='text-decoration-underline white--text font-weight-semibold'>Refresh?</a></span>",
        timeout: -1,
        closeButton: true,
      });
    }

    this.loadingState = false;
    this.loadingTimeout = false;

    if (!this.redesignNotificationSeen) {
      this.showRedesignNotification = true;
    }
  }

  redesignNotificationAcknowledged(): void {
    if (this.doNotShowRedesignNotificationAgain) {
      Vue.$cookies.set("redesignNotificationSeen2", "true", "1y");
    }

    this.showRedesignNotification = false;
  }

  widgetMovedOrResized(): void {
    this.$nextTick(() => {
      const data: Partial<DashboardI> = this.dashboardRef.$attrs;
      try {
        Dash.updateDashboardLayout({ id: data.id!, userId: User.userId, name: data.name!, widgets: data.widgets!, userIdsSharedWith: data.userIdsSharedWith! });
      } catch (error) {
        console.error("Saving changes to dashboard layout failed" + error);

        Snackbar.showSnackbar({
          text: "Widget was moved, but the changes could not be saved. <br> We recommend refreshing the page and trying again.",
          timeout: 7000,
        });
      }
    });
  }

  fabAction(action: string): void {
    switch (action) {
      case "add":
        this.dashboardControlDialog.open();
        break;
      case "edit":
        this.editMode = true;
        break;
      case "exitEdit":
        this.editMode = false;
        break;
    }
  }

  //Create 2D grid of dashboard to use when placing new widgets
  //Todo: maybe get number of rows dynamic?
  createGrid(): boolean[][] {
    const rows = 50;
    const cols = (this.dashboardRef as any).numberOfCols;

    const grid = Array.from({ length: rows }, () => Array.from({ length: cols }, () => false));

    this.activeWidgets.forEach(widget => {
      for (let i = 0; i < widget.height; i++) {
        for (let j = 0; j < widget.width; j++) {
          grid[widget.y + i][widget.x + j] = true;
        }
      }
    });

    return grid;
  }

  getCoordinates(width: number, height: number): { y: number; x: number } {
    const grid = this.createGrid();
    const areal = width * height;

    for (let y = 0; y <= grid.length; y++) {
      for (let x = 0; x <= grid[y].length - width; x++) {
        if (!grid[y][x]) {
          let tooBig = false;
          let arealCounter = 0;

          for (let i = 0; i < height; i++) {
            for (let j = 0; j < width; j++) {
              if (grid[y + i][x + j]) {
                tooBig = true;
                break;
              } else {
                arealCounter++;
              }

              if (arealCounter === areal) {
                return { y, x };
              }
            }

            if (tooBig) {
              break;
            }
          }
        }
      }
    }

    return { y: 0, x: 0 };
  }

  //NOTE: setting minWidth/minHeight on items creates a bug with the placeholder where it gets stuck on the minWidth
  //of the previous item when trying to move/resize another item with different values.
  //This bug is in the vue-responsive-dash library, so we should not use minWidth/minHeight unless this is fixed in an update.
  async addNewWidget(type: WidgetType): Promise<void> {
    let config;

    try {
      if (type.usingConfig) {
        switch (type.template) {
          case "VesselDataWidget":
            if (type.displayType === "VesselDataTable") config = await VesselDataWidgetConfiguration.newEmptyVesselDataWidgetConfig("VesselDataTable");
            if (type.displayType === "VesselDataGraph") config = await VesselDataWidgetConfiguration.newEmptyVesselDataWidgetConfig("VesselDataGraph");
            break;
          case WidgetTypes.performanceStatus.template:
            config = await PerfStatus.addNewConfig();
            break;
          case WidgetTypes.notification.template:
            config = await NotificationWidgetConfiguration.addNewConfig();
            break;
          case WidgetTypes.emissions.template:
            config = await CiiWidgetConfiguration.addNewConfig();
            break;
          case WidgetTypes.foulingOverview.template:
            config = await FoulingOverviewWidgetConfiguration.addNewConfig();
            break;
        }
      }

      const coordinates = this.getCoordinates(type.defaultWidth, type.defaultHeight);
      const widget: Widget = {
        id: 0,
        name: config ? type.defaultName : type.defaultName,
        x: coordinates.x,
        y: coordinates.y,
        width: type.defaultWidth,
        height: type.defaultHeight,
        active: true,
        configId: config ? config.id : -1,
        type: type,
        typeTemplate: type.displayType ? type.displayType : type.template,
      };

      const updatedDashboard = await Dash.addWidget(widget);
      //setting the id returned from database so we can target the DOM element using id.
      widget.id = updatedDashboard.widgets[updatedDashboard.widgets.length - 1].id;

      this.scrollAndHighlightElement(widget.id);
    } catch (error) {
      console.error("Creating new widget failed", error);

      Snackbar.showSnackbar({
        text: "Creating new widget failed.",
      });

      return;
    }
  }

  async toggleWidgetActive(widget: Widget): Promise<void> {
    if (widget.active) {
      try {
        await Dash.updateWidget({ id: widget.id, active: false });
      } catch (error) {
        console.error("Deactivating widget failed", error);

        Snackbar.showSnackbar({
          text: "Deactivating widget failed.",
        });

        return;
      }

      if (!this.activeWidgets.length) {
        this.editMode = false;
      }
    } else {
      try {
        const coordinates = this.getCoordinates(widget.width, widget.height);
        await Dash.updateWidget({ id: widget.id, active: true, y: coordinates.y, x: coordinates.x });
      } catch (error) {
        console.error("Activating widget failed", error);

        Snackbar.showSnackbar({
          text: "Activating widget failed.",
        });

        return;
      }

      this.scrollAndHighlightElement(widget.id);
    }
  }

  scrollAndHighlightElement(id: string | number): void {
    setTimeout(() => {
      const newElement = document.getElementById("item_" + id);

      if (newElement) {
        newElement.scrollIntoView({ behavior: "smooth" });
        newElement.classList.add("highlight");
      }
    }, 100);
  }

  beforeDestroy(): void {
    Snackbar.close();
  }
}
