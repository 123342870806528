












































































































































import { Component, Prop, PropSync, Vue } from "vue-property-decorator";
import store from "@/store";
import dateHelper from "Utilities/date-helper";
//  types
import { VesselDataWidgetColumn } from "@/types/VesselDataWidgetColumn";
import { LogVariable } from "@/types/logVariable";
import { ExtendedVessel } from "@/types/Vessel";
//  modules
import { getModule } from "vuex-module-decorators";
import LogVariablesModule from "@/store/clients/LogVariables.module";

const LogVariables = getModule(LogVariablesModule, store);

@Component({
  components: {},
})
export default class LogVariablesColumn extends Vue {
  @PropSync("column", { required: true }) syncColumn!: VesselDataWidgetColumn;
  @Prop({ default: () => [], required: true }) vessels!: ExtendedVessel[];
  @Prop({ required: true }) index!: number;
  @Prop({ type: Boolean, default: true }) hasColumnNameField!: boolean;
  @Prop({ type: Boolean, default: true }) hasColumnTimeSpanField!: boolean;
  @Prop({ type: Boolean, default: true }) validateLogVariablesByUnit!: boolean;
  @Prop({ type: String, default: "100%" }) maxWidth!: string;

  accumulatedAllowedUnits = ["rpm", "MT/hr", "MT/day", "knot", "kW", "kg/hr", "l/hr"];

  onColumnDelete(): void {
    this.$emit("delete", this.index);
  }

  isColumnMainVessel(vesselId: number): boolean {
    if (!this.syncColumn || !this.validateLogVariablesByUnit) return false;
    const columnFirstVariable = this.syncColumn.logVariables[0];
    if (!columnFirstVariable) return false;
    return vesselId === columnFirstVariable.vesselId;
  }

  selectedLogVariable(vesselId: number, keyName: string): LogVariable | string | undefined {
    if (!this.syncColumn.logVariables.length) return;
    const vesselLogVariable = this.syncColumn.logVariables.find(variable => variable?.vesselId === vesselId);
    if (keyName === "name") return vesselLogVariable?.name;
    if (keyName === "displayName") return vesselLogVariable?.displayName ?? vesselLogVariable?.name;
    if (keyName === "unit-name") return vesselLogVariable?.unit?.name;
    if (keyName === "unit-caption") return vesselLogVariable?.unit?.caption;
    return vesselLogVariable;
  }

  checkUnitError(vesselId: number): string | undefined {
    if (!this.syncColumn.logVariables.length || !this.validateLogVariablesByUnit) return;

    const columnFirstVesselLogVariableUnitName = this.syncColumn.logVariables[0].unit?.caption;
    const currentVesselLogVariable = this.syncColumn.logVariables.find((variable: LogVariable) => variable?.vesselId === vesselId);
    if (currentVesselLogVariable && currentVesselLogVariable.unit?.caption !== columnFirstVesselLogVariableUnitName) return "Variable using different unit";
  }

  clickField(vessel: ExtendedVessel): void {
    this.$emit("clickField", { vessel: vessel, index: this.index });
  }

  clickTimeSpanField(): void {
    this.$emit("clickTimeSpanField", this.index);
  }

  clearVariable(vesselId: number): void {
    const variableIndex = this.syncColumn.logVariables.findIndex(variable => variable.vesselId === vesselId);
    if (variableIndex !== -1) {
      this.syncColumn.logVariables.splice(variableIndex, 1);
    }
  }

  isVesselLogVariablesLoaded(vesselId: number): boolean {
    return Boolean(LogVariables.vesselsLogVariables.find(vessel => vessel.vesselId === vesselId)?.logVariables);
  }

  isUnitAccAllowed(unit: string): boolean {
    return this.accumulatedAllowedUnits.includes(unit);
  }

  validateColumnAccUnits(): string | undefined {
    if (
      (this.syncColumn.calculationType !== "FromAndToDates" && this.syncColumn.calculationType !== "Accumulated") ||
      (this.syncColumn.calculationType === "FromAndToDates" && this.syncColumn.fromAndToDatesCalculationType !== "Accumulated")
    )
      return;
    const error = `Accumulated calculation only accepts the following units: ${this.accumulatedAllowedUnits.join(", ")}`;
    if (this.hasNotAllowedUnit) return error;
  }

  get hasNotAllowedUnit(): boolean {
    return this.syncColumn.logVariables.some(variable => !this.isUnitAccAllowed(variable.unit?.caption));
  }

  get formattedWidgetDateRange(): string {
    return `${dateHelper.getFormatedDateTimeString(this.syncColumn.fromDate)} - ${dateHelper.getFormatedDateTimeString(this.syncColumn.toDate)}`;
  }

  get selectedTimeSpan(): string {
    switch (this.syncColumn.calculationType) {
      case "LastReceived":
        return "Last received value";
      case "LastVoyageAveraged":
        return "Last voyage averaged";
      case "LastVoyageAccumulated":
        return "Last voyage accumulated";
      case "FromAndToDates":
        return this.formattedWidgetDateRange;
      case "Averaged":
      case "Accumulated":
        const hours = dateHelper.getDatesDiffAsHours(this.syncColumn.fromDate, this.syncColumn.toDate);
        return `${this.syncColumn.calculationType} value for the last ${hours} hours`;
      default:
        return "Choose time span";
    }
  }
}
