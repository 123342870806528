





































































































import { Component, PropSync, Vue } from "vue-property-decorator";
import store from "@/store";
import LoadingTextWave from "@/components/LoadingTextWave.vue";
// modules
import { getModule } from "vuex-module-decorators";
import VesselsModule from "@/store/clients/Vessels.module";

const Vessels = getModule(VesselsModule, store);

@Component({
  components: {
    LoadingTextWave,
  },
})
export default class WidgetEditDialog extends Vue {
  @PropSync("value", { required: true }) isModalActive!: boolean;
  @PropSync("maxWidth", { default: 1000 }) dialogWidth!: number;

  isDialogExpanded = false;
  widgetNameInitial = ""; // copy of the widget name, to restore if the user cancel editing the widget name
  widgetName = "";
  editingWidgetName = false;

  get loading(): boolean {
    return Vessels.vesselsLoadingState;
  }

  open(name: string): void {
    this.widgetName = name;
    this.widgetNameInitial = name;
    this.isModalActive = true;
    this.$emit("open");
  }

  close(): void {
    (document.getElementsByClassName("WidgetEditDialog v-dialog--active")[0].scrollTop = 0), (this.isModalActive = false);
    this.isDialogExpanded = false;
    this.cancelEditWidgetName();
    this.$emit("close");
  }

  editWidgetName(): void {
    this.editingWidgetName = true;
    this.widgetName = (this.$refs["widgetName"] as any).internalValue;
    console.log("editWidgetName => (this.$refs", (this.$refs["widgetName"] as any).internalValue);
  }

  cancelEditWidgetName(): void {
    this.editingWidgetName = false;
    this.widgetName = this.widgetNameInitial;
  }

  saveWidgetName(): void {
    const newName = (this.$refs["widgetName"] as any).internalValue;
    this.widgetNameInitial = newName;
    this.editingWidgetName = false;
    this.$emit("onSaveWidgetName", newName);
  }
}
