


























import { Component, Vue, PropSync, Watch } from "vue-property-decorator";
import store from "@/store";
//  utilities
import itemMixin from "@/mixins/itemMixin.js";
import dateHelper from "Utilities/date-helper";
import stringifiedCalculationMethodForHeader from "./VDataTableCalculationMethodHeaders";
import Highcharts from "highcharts";
import { Chart } from "highcharts-vue";
import HighchartStock from "highcharts/modules/stock.js";
//  components
import LoadingTextWave from "@/components/LoadingTextWave.vue";
//  types
import { VesselDataWidgetConfig } from "@/types/VesselDataWidgetConfig";
import { ExtendedVessel } from "@/types/Vessel";
//  modules
import { getModule } from "vuex-module-decorators";
import VesselsModule from "@/store/clients/Vessels.module";

const Vessels = getModule(VesselsModule, store);
HighchartStock(Highcharts);

@Component({
  mixins: [itemMixin],
  components: {
    Highcharts: Chart,
    LoadingTextWave,
  },
})
export default class VesselDataGraph extends Vue {
  @PropSync("data", { required: true, type: Object }) initialConfig!: VesselDataWidgetConfig;
  @PropSync("loading", { type: Boolean, default: false }) isDataLoading!: boolean;

  isChartLoaded = false;
  chart!: any;
  reflowTimer!: number; // need to call reflow on the chart with some delay after widget resize, otherwise it will not resize properly

  @Watch("resizing")
  resized(val: boolean): void {
    this.reflowTimer = setTimeout(() => {
      if (!val && this.chart) {
        this.chart.reflow();
      }
    }, 500);
  }

  get extendedVessels(): ExtendedVessel[] {
    return Vessels.extendedVessels;
  }

  get stringifiedTimeSpan(): { text: string; tooltipText: string } | undefined {
    const column = this.initialConfig.columns[0];
    const hours = dateHelper.getDatesDiffAsHours(column.fromDate, column.toDate);
    if (!column) return;
    return stringifiedCalculationMethodForHeader(column.calculationType, hours);
  }

  get unitCaption(): string {
    return this.initialConfig.columns[0].logVariables[0].unit.caption;
  }

  get xAxisMaxAmount(): number {
    if (!this.getVesselsLogVariables) return 0;
    return this.getVesselsLogVariables.length > 10 ? 10 : this.getVesselsLogVariables.length - 1;
  }

  get yAxisMaxTick(): number | null {
    if (!this.getVesselsLogVariables) return null;
    return this.getVesselsLogVariables.reduce((previous: any, current: any) => (current.y > previous.y ? current : previous)).y;
  }

  get isScrollable(): boolean {
    return this.xAxisMaxAmount >= 10;
  }

  get getVesselNames(): string[] | undefined {
    if (!this.getVesselsLogVariables) return;
    return this.getVesselsLogVariables.map(variable => variable.vesselName);
  }

  get getVesselsLogVariables(): any[] | null {
    if (!this.initialConfig.columns[0].cachedResults.length) return null;
    return this.initialConfig.columns[0].cachedResults.map(variable => {
      return {
        y: parseFloat(variable.result.toFixed(variable.logVariableDisplayPrecision)),
        unit: variable.unitCaption,
        logVariableName: variable.logVariableName,
        vesselName: variable.vesselName,
        logVariableDisplayPrecision: variable.logVariableDisplayPrecision,
      };
    });
  }

  chartReady(chart: any): void {
    this.chart = chart;
    this.reflowTimer = setTimeout(() => {
      this.chart.reflow();
      this.isChartLoaded = true;
      this.isDataLoading = false;
    }, 500);
  }

  get options(): any {
    return {
      chart: {
        type: "bar",
        style: {
          fontFamily: "Helvetica Neue",
        },
      },
      title: {
        text: this.stringifiedTimeSpan?.tooltipText,
        style: {
          fontSize: "18px",
          fontWeight: "bold",
        },
      },
      subtitle: {
        text: this.unitCaption,
        style: {
          fontSize: "18px",
        },
      },
      xAxis: {
        categories: this.getVesselNames,
        labels: {
          style: {
            fontSize: "12px",
            fontWeight: "600",
          },
        },
      },
      yAxis: {
        title: { text: null },
        labels: {
          overflow: "justify",
        },
        max: this.yAxisMaxTick,
      },
      legend: {
        reversed: true,
      },
      plotOptions: {
        bar: {
          dataLabels: {
            enabled: true,
            style: {
              color: "#FFF",
              fontSize: "0.75rem",
              borderWidth: 0,
            },
          },
        },
        series: {
          stacking: "normal",
          borderRadius: 5,
          pointPadding: 0,
          tooltip: {
            headerFormat: "",
            pointFormat: "<b>{point.logVariableName}: {point.y:,.0f} {point.unit}</b><br/>",
          },
          dataLabels: {
            enabled: true,
            formatter: function (this: any): string {
              if (this.point.shapeArgs.height < this.point.logVariableName.length * 6) return "";
              return `${new Intl.NumberFormat(undefined, { maximumFractionDigits: this.point.logVariableDisplayPrecision }).format(this.point.y)} ${this.point.unit} ${this.point.logVariableName}`;
            },
            style: {
              textOutline: "none",
            },
          },
        },
      },
      credits: { enabled: false },
      series: [
        {
          color: "#001843c7",
          data: this.getVesselsLogVariables,
          maxPointWidth: 30,
          showInLegend: false,
        },
      ],
      responsive: {
        rules: [
          {
            condition: {
              minHeight: 600,
            },
            chartOptions: {
              xAxis: {
                min: 0,
                max: null,
                scrollbar: { enabled: false },
              },
            },
          },
          {
            condition: {
              maxHeight: 400,
            },
            chartOptions: {
              xAxis: {
                min: 0,
                max: this.xAxisMaxAmount,
                scrollbar: { enabled: this.isScrollable },
              },
            },
          },
        ],
      },
    };
  }

  onWindowResize(): void {
    setTimeout(() => {
      this.chart.reflow();
    }, 200);
  }

  created(): void {
    window.addEventListener("resize", this.onWindowResize);
  }

  beforeDestroy(): void {
    if (this.reflowTimer) clearTimeout(this.reflowTimer);
    window.removeEventListener("resize", this.onWindowResize);
  }
}
