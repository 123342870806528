

















































































import { Component, Vue, Prop } from "vue-property-decorator";
import { TableRow } from "@/types/widgetNotification";
// utilities
import dateHelper from "Utilities/date-helper";

@Component({})
export default class WarningTooltip extends Vue {
  @Prop() tableRow!: TableRow;

  get hasNoWarningIncidents(): boolean {
    if (!this.tableRow) return false;
    return this.tableRow.notificationModuleEnabled && this.tableRow.warningIncidents.amount === 0 && this.tableRow.activeConditions !== 0;
  }

  formatedDateString(date: string): string | null {
    if (!date) return null;
    return dateHelper.getFormatedDateTimeString(date);
  }
}
