import moment from "moment";

export default function stringifiedCalculationMethodForHeader(calculationType: string, hours?: number, fromAndToDatesCalculationType?: string, fromDate?: string, toDate?: string): { text: string; tooltipText: string } {
  const subHeader = {
    text: "",
    tooltipText: "",
  };

  const from = moment.utc(fromDate).format("YYYY-MM-DD HH:mm");
  const to = moment.utc(toDate).format("YYYY-MM-DD HH:mm");

  switch (calculationType) {
    case "Accumulated":
      subHeader.text = `ACC [${hours} hours]`;
      subHeader.tooltipText = `Accumulated data from the last ${hours} hours.`;
      break;
    case "Averaged":
      subHeader.text = `AVG [${hours} hours]`;
      subHeader.tooltipText = `Average data from the last ${hours} hours.`;
      break;
    case "LastReceived":
      subHeader.text = "Last received";
      subHeader.tooltipText = "Raw data from the last received vessel data.";
      break;
    case "LastVoyageAccumulated":
      subHeader.text = "ACC [Last voyage]";
      subHeader.tooltipText = "Accumulated data from the last completed voyage.";
      break;
    case "LastVoyageAveraged":
      subHeader.text = "AVG [Last voyage]";
      subHeader.tooltipText = "Average data from the last completed voyage.";
      break;
      case "FromAndToDates":
        if (fromAndToDatesCalculationType === "Averaged") {
          subHeader.text = `AVG <br/>${from} <br/> ${to}`
          subHeader.tooltipText = `Average data from ${from} to ${to}`;
        } else if (fromAndToDatesCalculationType === "Accumulated") {
          subHeader.text = `ACC <br/>${from} <br/> ${to}`
          subHeader.tooltipText = `Accumulated data from ${from} to ${to}`;
        }
        break;
  }

  return subHeader;
};
