














import { Component, Vue, Ref, Watch } from "vue-property-decorator";
import store from "@/store";
import itemMixin from "@/mixins/itemMixin.js";
//  components
import Map from "@/components/Map.vue";
// types
import { ExtendedVessel } from "@/types/Vessel";
// modules
import { getModule } from "vuex-module-decorators";
import VesselsModule from "@/store/clients/Vessels.module";

const Vessels = getModule(VesselsModule, store);

@Component({
  mixins: [itemMixin],
  components: {
    Map,
  },
})
export default class MapWidget extends Vue {
  @Ref("mapRef") mapRef!: Vue[];
  resized = false;
  //let map know it has been resized, otherwise tiles will not load properly
  //resizing is a computed property in the itemMixin
  @Watch("resizing")
  onResize(val: boolean): void {
    if (val) this.resized = val;
    setTimeout(() => (this.resized = false));
  }

  get vessels(): ExtendedVessel[] {
    return Vessels.extendedVessels.filter(vessel => this.hasDashboardEnabled(vessel));
  }

  hasDashboardEnabled(vessel: ExtendedVessel): boolean {
    return vessel.features.some(feature => feature.name === "Dashboard");
  }
}
