


























import { Component, Vue, Prop } from "vue-property-decorator";
import store from "@/store";

// modules
import { getModule } from "vuex-module-decorators";
import FoulingOverviewWidgetModule from "@/store/clients/FoulingOverviewWidget.module";

// types
import { FoulingStatus } from "./VTableData";
import { HullCoating } from "@/types/HullCoating";
import { SpeedLossStatistic } from "@/types/SpeedLossStatistic";
import { FoulingAddedConsumption } from "@/types/FoulingAddedConsumption";
import { FoulingChartConfig } from "@/types/FoulingChartConfig";

const FoulingOverviewWidgetConfig = getModule(FoulingOverviewWidgetModule, store);

@Component({})
export default class FoulingOverviewWidget extends Vue {
  @Prop({ required: true }) readonly vesselId!: number;
  @Prop({ required: true }) readonly vesselName!: string;

  // @Getters
  get hullCoating(): string {
    const hullCoating: HullCoating | null = FoulingOverviewWidgetConfig.hullCoatingVessels[this.vesselId];
    return hullCoating ? `${hullCoating.manufacturerName} | ${hullCoating.typeName} | ${hullCoating.brand}` : "Not Inputted";
  }

  get foulingConfig(): FoulingChartConfig | null {
    return FoulingOverviewWidgetConfig.foulingConfigs[this.vesselId];
  }

  get latestSpeedLossStatistics(): SpeedLossStatistic | null {
    return FoulingOverviewWidgetConfig.latestSpeedLossStatisticsForVessels[this.vesselId];
  }

  get latestSpeedLoss(): string {
    if (!this.latestSpeedLossStatistics || this.isBenchmarking) return "N/A";
    const speedLoss: number = Math.abs(this.latestSpeedLossStatistics.trendEndValue * -1);
    return `${speedLoss.toFixed(speedLoss != 0 ? 1 : 0)}%`;
  }

  get greenZoneEnds(): number {
    if (!this.latestSpeedLossStatistics) return 0 - 5;
    return this.latestSpeedLossStatistics.benchmark.level - 5;
  }

  get yellowZoneEnds(): number {
    return this.greenZoneEnds - 5;
  }

  get addedFuelConsumptions(): FoulingAddedConsumption[] {
    return FoulingOverviewWidgetConfig.addedFuelConsumptionForVessels[this.vesselId].filter(c => c.usingDerivedStw === this.foulingConfig?.useDerivedStw);
  }

  get latestAddedConsumption(): FoulingAddedConsumption | null {
    return this.addedFuelConsumptions?.length > 0 ? this.addedFuelConsumptions[this.addedFuelConsumptions.length - 1] : null;
  }

  get cumulativeAddedFuelConsumption(): string {
    return !this.latestAddedConsumption || this.latestAddedConsumption.fromDate !== this.latestSpeedLossStatistics?.fromDate || this.isBenchmarking
      ? "N/A"
      : `${Math.round(Number(this.latestAddedConsumption.cumulativeAddedConsumption)).toLocaleString()} MT/Days`;
  }

  get isBenchmarking(): boolean {
    if (!this.foulingConfig) return false;
    return !this.latestSpeedLossStatistics?.benchmark.end && !this.foulingConfig.useManualBenchmark;
  }

  get foulingStatus(): FoulingStatus {
    if (this.isBenchmarking) return FoulingStatus.BENCHMARKING;
    if (this.latestSpeedLossStatistics == null) return FoulingStatus.NOT_AVAILABLE;
    if (this.latestSpeedLossStatistics.trendEndValue < this.yellowZoneEnds) return FoulingStatus.HIGH;
    if (this.latestSpeedLossStatistics.trendEndValue < this.greenZoneEnds && this.latestSpeedLossStatistics.trendEndValue > this.yellowZoneEnds) return FoulingStatus.MEDIUM;
    if (this.latestSpeedLossStatistics.trendEndValue > this.greenZoneEnds) return FoulingStatus.NORMAL;

    return FoulingStatus.NOT_AVAILABLE;
  }

  get foulingStatusIcon(): string | null {
    if (this.isBenchmarking) return "mdi-gauge";

    switch (this.foulingStatus) {
      case FoulingStatus.HIGH:
        return "mdi-alert-circle";
      case FoulingStatus.MEDIUM:
        return "mdi-alert-circle";
      case FoulingStatus.NORMAL:
        return "mdi-check-circle";
      case FoulingStatus.BENCHMARKING:
        return "mdi-gauge";
      default:
        return null;
    }
  }

  get foulingStatecolor(): string {
    switch (this.foulingStatus) {
      case FoulingStatus.HIGH:
        return "#ff5252";
      case FoulingStatus.MEDIUM:
        return "#ffc107";
      case FoulingStatus.NORMAL:
        return "#4caf50";
      case FoulingStatus.BENCHMARKING:
        return "#003B42";
      default:
        return "#fff";
    }
  }
}
