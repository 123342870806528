




















































































import { Component, Vue, Prop } from "vue-property-decorator";

@Component({})
export default class WelcomeWidget extends Vue {
  @Prop({ default: false }) dialogMode!: boolean;
}
