var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{staticClass:"dashboard__widget widget__performance-status",class:_vm.moving ? 'isMoving' : '',attrs:{"elevation":_vm.moving ? 15 : 4,"width":_vm.width,"height":_vm.height}},[_c('v-toolbar',{attrs:{"color":"neutralLighten100","elevation":"0","dense":""}},[_c('v-icon',{staticClass:"mr-2",attrs:{"color":"primaryLighten80"}},[_vm._v("mdi-format-list-bulleted")]),_c('v-toolbar-title',[_c('div',{staticClass:"d-flex flex-row"},[_vm._v(" "+_vm._s(_vm.widgetRef.name)+" "),_c('v-tooltip',{attrs:{"top":"","color":"primary lighten-2","max-width":"350"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-icon',_vm._g({staticClass:"ml-2",attrs:{"color":"primaryLighten80"}},on),[_vm._v("help_outline")])]}}])},[_c('span',[_c('p',[_vm._v("Deviation from benchmark")]),_vm._v("The performances status is based in the deviation from the last benchmark. Benchmarks are reset after docking periods. "),_c('br'),_c('br'),_vm._v("Tip: you can hover the status icon to see more details. ")])])],1)]),_c('v-spacer'),_c('v-tooltip',{attrs:{"top":"","color":"primary lighten-2"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('div',_vm._g({},on),[_c('v-btn',{attrs:{"disabled":_vm.isPartOfSharedDashboard,"icon":"","color":"primaryLighten80","loading":_vm.isDataLoading},on:{"click":function($event){return _vm.WidgetEditDialog.open(_vm.widgetRef.name)}}},[_c('v-icon',[_vm._v("edit")])],1)],1)]}}])},[(_vm.isPartOfSharedDashboard)?_c('span',[_vm._v("Can not edit shared dashboard")]):_c('span',[_vm._v("Edit widget configuration")])])],1),(_vm.isDataLoading)?_c('LoadingTextWave',{staticClass:"mt-10"}):_c('section',{staticClass:"widget-content"},[_c('v-card-text',{staticClass:"px-0 py-0"},[_c('v-data-table',{attrs:{"headers":_vm.tableHeaders,"items":_vm.tableRows,"loading":_vm.isDataLoading,"dense":"","loading-text":"Loading... Please wait","no-data-text":"Click the edit-icon in the top right corner to start adding vessels","height":"100%","fixed-header":"","hide-default-footer":"","disable-pagination":""},scopedSlots:_vm._u([{key:"header.name",fn:function(ref){
var header = ref.header;
return [_c('span',{staticClass:"primary--text overline"},[_vm._v(_vm._s(header.text))])]}},{key:"header.speed",fn:function(ref){
var header = ref.header;
return [_c('v-tooltip',{attrs:{"top":"","color":"primary lighten-2"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('span',_vm._g({staticClass:"primary--text overline"},on),[_vm._v(_vm._s(header.text))])]}}],null,true)},[_c('span',[_vm._v("Shaft Power vs. Ship Speed Log")])])]}},{key:"header.rpm",fn:function(ref){
var header = ref.header;
return [_c('v-tooltip',{attrs:{"top":"","color":"primary lighten-2"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('span',_vm._g({staticClass:"primary--text overline"},on),[_vm._v(_vm._s(header.text))])]}}],null,true)},[_c('span',[_vm._v("Shaft Power vs. Shaft RPM")])])]}},{key:"header.sfr",fn:function(ref){
var header = ref.header;
return [_c('v-tooltip',{attrs:{"top":"","color":"primary lighten-2"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('span',_vm._g({staticClass:"primary--text overline"},on),[_vm._v(_vm._s(header.text))])]}}],null,true)},[_c('span',[_vm._v("Specific Fuel Rate vs. Shaft Power")])])]}},{key:"item.name",fn:function(ref){
var item = ref.item;
return [_c('router-link',{staticClass:"black--text text-decoration-none",attrs:{"to":("/Vessel/" + (item.id) + "/" + (encodeURIComponent(item.name)))}},[_vm._v(" "+_vm._s(item.name)+" ")])]}},{key:"item.speed",fn:function(ref){
var item = ref.item;
return [_c('v-tooltip',{attrs:{"top":"","color":"primary lighten-2"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('img',_vm._g({staticClass:"status-icon mt-1",attrs:{"src":item.speed.statusIcon}},on))]}}],null,true)},_vm._l((item.speed.subStatuses),function(status,index){return _c('span',{key:index},[_c('StatusTooltip',{attrs:{"status":status,"index":index}})],1)}),0)]}},{key:"item.rpm",fn:function(ref){
var item = ref.item;
return [_c('v-tooltip',{attrs:{"top":"","color":"primary lighten-2"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('img',_vm._g({staticClass:"status-icon mt-1",attrs:{"src":item.rpm.statusIcon}},on))]}}],null,true)},_vm._l((item.rpm.subStatuses),function(status,index){return _c('span',{key:index},[_c('StatusTooltip',{attrs:{"status":status,"index":index}})],1)}),0)]}},{key:"item.sfr",fn:function(ref){
var item = ref.item;
return [_c('v-tooltip',{attrs:{"top":"","color":"primary lighten-2"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('img',_vm._g({staticClass:"status-icon mt-1",attrs:{"src":item.sfr.statusIcon}},on))]}}],null,true)},_vm._l((item.sfr.subStatuses),function(status,index){return _c('span',{key:index},[_c('StatusTooltip',{attrs:{"status":status,"index":index}})],1)}),0)]}}],null,true)})],1)],1),_c('WidgetEditDialog',{ref:"WidgetEditDialog",attrs:{"value":_vm.isModalActive},on:{"update:value":function($event){_vm.isModalActive=$event},"close":_vm.onModalClose,"onSaveWidgetName":_vm.onSaveWidgetName},scopedSlots:_vm._u([{key:"content",fn:function(){return [_c('VesselSelect',{attrs:{"vesselsList":_vm.extendedVessels,"selectedVesselsList":_vm.selectedVessels,"requiredFeatures":['Diagnostics', 'Dashboard']},on:{"update:selectedVesselsList":function($event){_vm.selectedVessels=$event},"update:selected-vessels-list":function($event){_vm.selectedVessels=$event}}})]},proxy:true},{key:"actions",fn:function(){return [_c('v-spacer'),_c('v-btn',{staticClass:"font-weight-semibold",attrs:{"text":"","color":"primary"},on:{"click":function($event){return _vm.closeEditDialog()}}},[_vm._v(" Cancel ")]),_c('v-btn',{staticClass:"font-weight-semibold",attrs:{"color":"accent"},on:{"click":function($event){return _vm.saveConfig()}}},[_vm._v(" Save ")])]},proxy:true}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }