export interface TableHeader {
  text: string | undefined;
  value: string;
  sortable?: boolean;
  align?: string;
  width?: string;
}

export interface TableRow {
  id: number;
  name: string;
}

export enum FoulingStatus {
  NOT_AVAILABLE,
  HIGH,
  MEDIUM,
  LOW,
  NORMAL,
  BENCHMARKING,
}

export const tableHeaders: TableHeader[] = [
  {
    text: "Vessel Name",
    value: "name",
    sortable: true,
    align: "left",
    width: "150px",
  },
  {
    text: "Fouling status",
    value: "foulingStatus",
    sortable: false,
    align: "center",
    width: "50px",
  },
  {
    text: "Speed Loss Compared to Baseline",
    value: "speedLoss",
    sortable: false,
    align: "center",
    width: "100px",
  },
  {
    text: "Est. Cumulative Added Consumption",
    value: "cumulativeAddedConsumption",
    sortable: false,
    align: "center",
    width: "100px",
  },
  {
    text: "Hull coating",
    value: "hullCoating",
    sortable: false,
    align: "center",
    width: "100px",
  },
];
