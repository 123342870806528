













































































































































































































































import { Component, Ref, Vue } from "vue-property-decorator";
import { getModule } from "vuex-module-decorators";
import store from "@/store";
import DashboardModule from "@/store/clients/Dashboard.module";
import ConfirmDialog from "@/components/ConfirmDialog.vue";
import WelcomeWidget from "@/components/widgets/WelcomeWidget.vue";
import { WidgetType } from "@/types/widgetType";
import { Widget } from "@/types/widget";
import VesselDataWidgetConfiguration from "@/store/clients/VesselDataWidgetConfig.module";
import WidgetTypesModule from "@/store/clients/WidgetTypes.module";
import PerformanceStatusWidgetModule from "@/store/clients/PerformanceStatusWidget.module";
import NotificationWidgetModule from "@/store/clients/NotificationWidget.module";
import CiiWidgetModule from "@/store/clients/CiiWidget.module";
import FoulingOverviewWidgetModule from "@/store/clients/FoulingOverviewWidget.module";

const Dashboard = getModule(DashboardModule, store);
const VesselDataWidgetConfig = getModule(VesselDataWidgetConfiguration, store);
const WidgetTypes = getModule(WidgetTypesModule, store);
const PerfStatus = getModule(PerformanceStatusWidgetModule, store);
const NotificationWidgetConfiguration = getModule(NotificationWidgetModule, store);
const CiiWidgetConfiguration = getModule(CiiWidgetModule, store);
const FoulingOverviewWidgetConfig = getModule(FoulingOverviewWidgetModule, store);

@Component({
  components: {
    ConfirmDialog,
    WelcomeWidget,
  },
})
export default class DashboardControlDialog extends Vue {
  @Ref("confirmDelete") confirmDelete!: any;

  dialog = false;
  dialogRootView = true;
  widgetTypes = WidgetTypes.allAddable;
  selectedWidgetType: WidgetType = this.widgetTypes[0];
  instructionsDialog = false;

  get widgetsOfSelectedType(): Widget[] {
    return Dashboard.activeDashboardWidgets.filter(widget => widget.type?.defaultName === this.selectedWidgetType.defaultName);
  }

  get activeWidgets(): Widget[] {
    return Dashboard.activeWidgets;
  }

  get addButtonEnabled(): boolean {
    return this.selectedWidgetType?.multiple ? true : this.widgetsOfSelectedType.length < 1;
  }

  open(): void {
    this.dialog = true;
    this.dialogRootView = true;
  }

  goToDetails(type: WidgetType): void {
    this.dialogRootView = false;
    this.selectedWidgetType = type;
  }

  async handleDeleteWidget(widget: Widget): Promise<void> {
    const confirmed = await this.confirmDelete.open("Delete widget", "Are you sure you want to delete this widget? ");

    if (confirmed) {
      await Dashboard.deleteWidget(widget.id);

      if (widget.type?.usingConfig) {
        switch (widget.type.defaultName) {
          case WidgetTypes.VesselDataTable.defaultName:
            await VesselDataWidgetConfig.deleteVesselDataWidgetConfig(widget.configId)
              .then(() => console.log("Successfully deleted config."))
              .catch(error => console.error(error));
            break;
          case WidgetTypes.VesselDataGraph.defaultName:
            await VesselDataWidgetConfig.deleteVesselDataWidgetConfig(widget.configId)
              .then(() => console.log("Successfully deleted config."))
              .catch(error => console.error(error));
            break;
          case WidgetTypes.performanceStatus.defaultName:
            await PerfStatus.deleteWidgetConfig(widget.configId)
              .then(() => console.log("Successfully deleted config."))
              .catch(error => console.error(error));
            break;
          case WidgetTypes.notification.defaultName:
            await NotificationWidgetConfiguration.deleteWidgetConfig(widget.configId)
              .then(() => console.log("Successfully deleted config."))
              .catch(error => console.error(error));
            break;
          case WidgetTypes.emissions.defaultName:
            await CiiWidgetConfiguration.deleteCiiWidgetConfig(widget.configId)
              .then(() => console.log("Successfully deleted config."))
              .catch(error => console.error(error));
            break;
          case WidgetTypes.foulingOverview.defaultName:
            await FoulingOverviewWidgetConfig.deleteWidgetConfig(widget.configId)
              .then(() => console.log("Successfully deleted config."))
              .catch(error => console.error(error));
            break;
        }
      }
    }
  }

  addNewWidget(): void {
    if (this.selectedWidgetType) {
      this.$emit("addNewWidget", this.selectedWidgetType, true);
    }
  }

  switchToggle(widget: Widget): void {
    this.$emit("toggleWidgetActive", widget);
  }

  async saveWidgetName(widget: Widget): Promise<void> {
    const newName = (this.$refs["textFieldName-" + widget.id] as any)[0].internalValue;

    try {
      await Dashboard.updateWidget({ id: widget.id, name: newName });
      //if widget has config, also update the corresponding config in DB
      if (widget.type?.usingConfig) {
        let newConfig: any;

        switch (widget.type.defaultName) {
          case WidgetTypes.VesselDataTable.defaultName:
            await VesselDataWidgetConfig.getVesselDataWidgetConfigById(widget.configId).then(config => (newConfig = Object.assign(config, { configurationName: newName })));
            await VesselDataWidgetConfig.updateVesselDataWidgetConfig(newConfig);
            break;
          case WidgetTypes.VesselDataGraph.defaultName:
            await VesselDataWidgetConfig.getVesselDataWidgetConfigById(widget.configId).then(config => (newConfig = Object.assign(config, { configurationName: newName })));
            await VesselDataWidgetConfig.updateVesselDataWidgetConfig(newConfig);
            break;
        }
      }
    } catch (err) {
      console.warn("Saving widget name failed: ", err);
    }
  }
}
