


























import { Component, Vue, Prop } from "vue-property-decorator";
import dateHelper from "Utilities/date-helper";

@Component({})
export default class StatusTooltip extends Vue {
  @Prop() status!: any;
  @Prop() index!: number;

  formatDate(date: string): string {
    return dateHelper.getFormatedDateTimeString(date);
  }

  get statusName(): string {
    return this.status.name.split(" -")[0];
  }
}
