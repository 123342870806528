var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-data-table',{attrs:{"dense":"","headers":_vm.tableHeaders,"items":_vm.tableRowItems,"loading":_vm.isDataLoading,"loading-text":"Loading... Please wait","custom-sort":_vm.customSort,"height":"100%","fixed-header":"","hide-default-footer":"","disable-pagination":""},scopedSlots:_vm._u([_vm._l((_vm.tableHeaders),function(header){return {key:("header." + (header.value)),fn:function(ref){
var header = ref.header;
return [_c('div',{staticClass:"d-inline-flex flex-column"},[_c('span',{staticClass:"primary--text",staticStyle:{"line-height":"1.5rem","font-size":"0.9rem"},domProps:{"innerHTML":_vm._s(header.text)}})])]}}}),{key:"header.requiredCii",fn:function(ref){
var header = ref.header;
return [_c('v-tooltip',{attrs:{"left":"","color":"primary lighten-2","max-width":"300"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('div',{staticClass:"d-inline-flex flex-column"},[_c('span',{staticClass:"primary--text",staticStyle:{"line-height":"1.5rem","font-size":"0.9rem"},domProps:{"innerHTML":_vm._s(header.text)}})]),_c('v-icon',_vm._g({staticClass:"ml-1 mb-1",attrs:{"color":"primary lighten-2"}},on),[_vm._v(" mdi-information-outline ")])]}}],null,true)},[_c('span',[_vm._v("Required CII is calculated using the formula: "),_c('i',[_vm._v("a * Capacity"),_c('sup',[_vm._v("-c")])]),_vm._v(" , where a and c are constants based on the vessel type and weight. Capacity is the vessel weight in either gross tonnage or deadweight, depending on vessel type")])])]}},{key:"header.ciiTrend",fn:function(ref){
var header = ref.header;
return [_c('v-tooltip',{attrs:{"left":"","color":"primary lighten-2","max-width":"300"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('div',{staticClass:"d-inline-flex flex-column"},[_c('span',{staticClass:"primary--text",staticStyle:{"line-height":"1.5rem","font-size":"0.9rem"},domProps:{"innerHTML":_vm._s(header.text)}})]),_c('v-icon',_vm._g({staticClass:"ml-1 mb-1",attrs:{"color":"primary lighten-2"}},on),[_vm._v(" mdi-information-outline ")])]}}],null,true)},[_c('span',[_vm._v("CII Trend column shows the trend for the last 7 days relative to the second-to-last 7 days from the last exported date")])])]}},{key:"item.ciiRating",fn:function(ref){
var item = ref.item;
return [_c('v-chip',{staticStyle:{"font-weight":"700","font-size":"0.8rem","color":"#fff"},attrs:{"color":_vm.ratingColor(item.ciiRating),"small":""}},[_vm._v(" "+_vm._s(item.ciiRating)+" ")])]}},{key:"item.ciiTrend",fn:function(ref){
var item = ref.item;
return [(item.ciiTrend && item.ciiTrend.last7DaysAverage !== 0)?_c('v-tooltip',{attrs:{"color":"primary lighten-2","max-width":"320","left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-icon',_vm._g({staticClass:"mr-1 mb-1",attrs:{"color":_vm.ciiTrendStyles(item.ciiTrend).color}},on),[_vm._v(" "+_vm._s(_vm.ciiTrendStyles(item.ciiTrend).icon)+" ")])]}}],null,true)},[_c('span',[_vm._v("Attained average CII for the last 7 days: "+_vm._s(item.ciiTrend.last7DaysAverage.toFixed(2)))])]):_c('span',[_vm._v("No Data")])]}},(_vm.vesselsNoDataList.length)?{key:"body.append",fn:function(){return _vm._l((_vm.vesselsNoDataList),function(vessel,i){return _c('tr',{key:i},[_c('td',[_vm._v(_vm._s(vessel.name))]),_c('td',[_vm._v(_vm._s(vessel.imoNumber))]),_c('td',[_vm._v(_vm._s(_vm.vesselWeight(vessel.id)))]),_vm._l((6),function(index){return _c('td',{key:index},[_vm._v("No Data")])})],2)})},proxy:true}:null,(_vm.isNoData)?{key:"no-data",fn:function(){return [_c('div',{staticClass:"no-data-slot"},[_c('h6',{staticClass:"text-h6 mb-1"},[_vm._v("There are no selected vessels.")]),_c('p',{staticClass:"subtitle-1 mb-6"},[_vm._v("Click to start adding vessels")]),_c('v-btn',{staticClass:"font-weight-semibold",attrs:{"disabled":_vm.isPartOfSharedDashboard,"color":"accent"},on:{"click":function($event){return _vm.$emit('openEdit')}}},[_c('v-icon',{staticClass:"mr-2",attrs:{"color":"white"}},[_vm._v("mdi-table-plus")]),_vm._v(" Add vessels ")],1)],1)]},proxy:true}:null,(_vm.isDataLoading)?{key:"progress",fn:function(){return [_c('v-progress-linear',{attrs:{"indeterminate":""}}),_c('div',{staticClass:"progress-linear-text"},[_c('p',{staticClass:"mb-1"},[_vm._v("Loading...Please wait")])])]},proxy:true}:null],null,true)})}
var staticRenderFns = []

export { render, staticRenderFns }